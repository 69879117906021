//@ts-nocheck

import React,{useState,useEffect,Suspense,useRef,useMemo,Fragment,useContext} from 'react'
import * as THREE from 'three'
import {Canvas,useFrame} from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera, } from '@react-three/drei';
import { LinearEncoding, sRGBEncoding } from 'three/src/constants';
import { LineBasicMaterial,MeshPhysicalMaterial,Vector2,} from 'three';
import { PlaneBufferGeometry, MeshBasicMaterial,MeshStandardMaterial } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import ReactAudioPlayer from 'react-audio-player';
import Cookies from 'js-cookie';
import {ReactMic} from 'react-mic'
import Login from '../screens/Login';
import axios from 'axios';
import LoadingAI from '../lottie/AI_loading.json'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import createAnimation from '../converter';
import blinkData from '../blendDataBlink.json'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate} from 'react-router-dom';
import Lottie from "lottie-react";
import { CLogo, CPlusLogo, CSharpLogo, ReactLogo, JavascriptLogo, PythonLogo, JavaLogo, RubyLogo, DotNetLogo, SqlLogo, Mic} from '../theme/images';
import ResultContext from '../Context/AI_Result_Context';
import { BASE_URL } from '../services/constant';
import CanvasLoader from './CanvasLoader';

const _ = require('lodash')

const host = 'https://talking-avatar.onrender.com'

function Avatar({ avatar_url, speak, setSpeak, text, setAudioSource, playing }) {

  let gltf = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;

  const [
    bodyTexture,
    eyesTexture,
    teethTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    teethNormalTexture,
    // teethSpecularTexture,
    hairTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ] = useTexture([
    "/images/body.webp",
    "/images/eyes.webp",
    "/images/teeth_diffuse.webp",
    "/images/body_specular.webp",
    "/images/body_roughness.webp",
    "/images/body_normal.webp",
    "/images/teeth_normal.webp",
    // "/images/teeth_specular.webp",
    "/images/h_color.webp",
    "/images/tshirt_diffuse.webp",
    "/images/tshirt_normal.webp",
    "/images/tshirt_roughness.webp",
    "/images/h_alpha.webp",
    "/images/h_normal.webp",
    "/images/h_roughness.webp",
  ]);

  _.each([
    bodyTexture,
    eyesTexture,
    teethTexture,
    teethNormalTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture
  ], t => {
    t.encoding = sRGBEncoding;
    t.flipY = false;
  });

  bodyNormalTexture.encoding = LinearEncoding;
  tshirtNormalTexture.encoding = LinearEncoding;
  teethNormalTexture.encoding = LinearEncoding;
  hairNormalTexture.encoding = LinearEncoding;


  gltf.scene.traverse(node => {


    if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {

      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;


      if (node.name.includes("Body")) {

        node.castShadow = true;
        node.receiveShadow = true;

        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyTexture;
        // node.material.shininess = 60;
        node.material.roughness = 1.7;

        // node.material.specularMap = bodySpecularTexture;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.6, 0.6);

        morphTargetDictionaryBody = node.morphTargetDictionary;

        node.material.envMapIntensity = 0.8;
        // node.material.visible = false;

      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;


      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {

        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.7;


      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        node.material.map = hairTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;

        node.material.transparent = true;
        node.material.depthWrite = false;
        node.material.side = 2;
        node.material.color.setHex(0x000000);

        node.material.envMapIntensity = 0.3;


      }

      if (node.name.includes("TSHIRT")) {
        node.material = new MeshStandardMaterial();

        node.material.map = tshirtDiffuseTexture;
        node.material.roughnessMap = tshirtRoughnessTexture;
        node.material.normalMap = tshirtNormalTexture;
        node.material.color.setHex(0xffffff);

        node.material.envMapIntensity = 0.5;


      }

      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }

    }

  });

  const [clips, setClips] = useState([]);

  const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), []);

  useEffect(() => {

    if (speak === false)
      return;
      console.log('text inside--->',text)
    if(text != undefined || text!= null || text != ''){
     makeSpeech(text)
      .then(response => {

        let { blendData, filename } = response.data;
        console.log(response);
        let newClips = [
          createAnimation(blendData, morphTargetDictionaryBody, 'HG_Body'),
          createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')];

        filename = host + filename;

        setClips(newClips);
        setAudioSource(filename);
      })
      .catch(err => {
        console.error(err);
        setSpeak(false);
      })
    }
  }, [speak]);

  let idleFbx = useFBX('./idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {

    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }

    return track;

  });

  useEffect(() => {

    let idleClipAction = mixer.clipAction(idleClips[0]);
    idleClipAction.play();

    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();


  }, []);

  // Play animation clips when available
  useEffect(() => {

    if (playing === false)
      return;

    _.each(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();

    });

  }, [playing]);


  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}

function makeSpeech(text) {
  if(text != undefined || text != null || text != ''){
  return axios.post(host + '/talk', { text });
  }
}


const AI_Interview = () => {

  const [playing, setPlaying] = useState(false);
  const [chats, setChats] = useState([{ msg: '', who: '', exct: '' }])
  const [text, setText] = useState("Hello I am joi, your 3D virtual assistant.");
  const [msg, setMsg] = useState("");
  const [exct,setExct] = useState()
  const [load, setLoad] = useState(false);
  const [navigationData,setNaviagtionData] = useState('/')
  const audioPlayer = useRef();
  const [recording,setRecording] = useState(false)
  const [show,setShow] = useState(false)
  const [speak, setSpeak] = useState(false);
  const [audioSource, setAudioSource] = useState(null);
  const [startInterview,setStartInterview] =  useState(false)
  const [interviewId,setInterviewId] = useState('')
  const [epochTime,setEpochTime] = useState('')
  const [recordedAudio,setRecordedAudio] = useState('')
  const [confirmation,setConfirmation] = useState(false)
  const [isAuth,setIsAuth] = useState(false)
  const [modalShow,setModalShow] = useState(false)
  const [language,setLanguage] = useState()
  const [isStop,setIsStop] = useState(false)
  const [level,setLevel] = useState('')
  const navigation = useNavigate()
  const chatBox = useRef(null)
  const messageRef = useRef(null)
  const {addData} = useContext(ResultContext)

  const getResponse = async (msg,flagText) => {
    if (msg === '') {
      setShow(true)
      toast.error("Promt can't be empty.[In some browsers mic may not work]");
      return;
    }
   else if (load === true || speak === true) {
      setShow(true)
      toast.error("Already generating response!");
      return;
    }

    setLoad(true);
    setChats(chats => [...chats, { msg:msg, who: 'me' }])
    setMsg("");

    if(flagText === 'start'){
     axios.get(`${BASE_URL}create_interview/${language}/${level}/10`,)
    .then((response)=>{
      if(response?.data != undefined || response?.data != '' || response?.data != null){
      console.log('AI Response Start',response?.data)
      setInterviewId(response?.data?.interview_id)
      setEpochTime(response?.data?.epoch_time)
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
      const interview_id = response?.data?.interview_id;
      const epoch_time = response?.data?.epoch_time;

      axios.post(`${BASE_URL}start_interview/`,{
          epoch_time: epoch_time,
          interview_id: interview_id,
          can_response: "",
          bot_response: ""
      
      }).then((response_start)=>{
        console.log('AI Response Start',response_start?.data)
      setSpeak(true)
      setText(response_start?.data?.bot_response)
      setLoad(false);
      setShow(true)
      }).catch((err)=>{
      console.log('AI err Start',err)
      setSpeak(false)
      setText("Sorry, API isn't working currently. try after some time.")
      setLoad(false)
      setStartInterview(false)
      setShow(false)
      toast.error('API is not working! Try after some time.')
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
      })
      }
      else{
        toast.error('API is not working! Try after some time.')
      }
    })
    .catch((err)=>{
      console.log('AI err Start',err)
      setSpeak(false)
      setText("Sorry, API isn't working currently. try after some time.")
      setLoad(false)
      setStartInterview(false)
      setShow(false)
      toast.error('API is not working! Try after some time.')
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
    })
    }

    else if(flagText === 'converse'){
      const data = {
        epoch_time : epochTime,
        interview_id : interviewId,
        can_response : msg,
        bot_response : '',
      }
      console.log('message---->',text)
      axios.post(`${BASE_URL}converse`,data)
      .then((response)=>{
        console.log('AI Response Converse',response)
        setText(response.data.bot_response)
        setInterviewId(response.data.interview_id)
        setEpochTime(response.data.epoch_time)
        setSpeak(true)
        setLoad(false)
        chatBox.current.scrollTop = chatBox.current.scrollHeight;
      })
      .catch((err)=>{
        setSpeak(false)
        console.log('AI Error Converse',err)
        setText("Sorry, API isn't working currently. try after some time.")
        setLoad(false)
        toast.error('API is not working! Try after some time.')
        chatBox.current.scrollTop = chatBox.current.scrollHeight;
      })
    }

    else if(flagText === 'stop_interview'){
      const data = {
        epoch_time : epochTime,
        interview_id : interviewId,
        can_response : '',
        bot_response : '',
      }
      axios.post(`${BASE_URL}stop_interview`,data)
      .then((response)=>{
        console.log('AI Response Stop Interview',response)
        const epoch_time = response?.data?.epoch_time;
        const interview_id = response?.data?.interview_id;

        axios.post(`${BASE_URL}get_feedback/`,{
          epoch_time : epoch_time,
          interview_id : interview_id,
          can_response : '',
          bot_response : '',
        })
        .then((response_stop)=>{
          console.log('AI Response Stop Interview 2-->',response_stop)
        setText(response_stop?.data?.bot_response + `${response_stop?.data?.details.length > 0 ? 'Redirecting you to the Result page.' : 'Redirecting you to the Home page.'}`)
        setNaviagtionData(response_stop?.data?.details.length > 0 ? '/ai_result' : '/')
        addData(response_stop?.data?.details)
        setSpeak(true)
        setLoad(false)
        setShow(false)
        setStartInterview(true)
        setIsStop(true)
        })
        .catch((error)=>{
          setSpeak(false)
          console.log('AI Error Stop Interview',error)
          toast.error('API is not working! Try after some time.')
          setLoad(false)
        })
      })
      .catch((error)=>{
        setSpeak(false)
        console.log('AI Error Stop Interview',error)
        toast.error('API is not working! Try after some time.')
        setLoad(false)
      })
    }
    

    // const start = new Date();
    // fetch("https://chadgpt-weeh.onrender.com/" + msg, requestOptions)
    //   .then(response => response.text())
    //   .then(result => {
    //     console.log("airesult--->",result)
    //     const timeTaken = (new Date()) - start;
    //     setSpeak(true);
    //     setText("" + result.substring(1, result.length - 1));
    //     setexct(timeTaken / 1000);
    //     setLoad(false)
    //   })
    //   .catch((error) => { alert('error: ', error.message); setLoad(false); setText("Sorry, API isn't working currently. try after some time.") });

  }

  // End of play
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
    setShow(true)
    setText('')
    if(isStop == true){
      navigation(navigationData)
    }
  }

  // Player is read
  function playerReady(e) {
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
    setChats(chats => [...chats, { msg: text, who: 'bot', exct: exct }]);

  }

  const {
    transcript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const startListening = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening()
      setRecording(true)
    }
    else {
      toast.error("Voice recognision not supported by browser.")
    }

  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setRecording(false)
    setConfirmation(true) 
  }

  const onStop = (recordedData)=>{
    setRecordedAudio(new Blob([recordedData.blob], { type: "audio/webm;codecs=opus" }))
  }

  const handleCancle = ()=>{
    setMsg('')
    setShow(true)
    setRecording(false)
    setConfirmation(false)
    setRecordedAudio('')
  }

  const handleSubmit = ()=>{
    getResponse(msg,'converse');
    setShow(false)
    setConfirmation(false)
    setRecordedAudio('')
  }

  const handleStartInterview = ()=>{
    getResponse('Start Interview','start')
    setStartInterview(true)
  }

  useEffect(() => {
    setMsg(transcript);
    if(msg !== ''){
      messageRef.current.scrollTop = messageRef.current.scrollHeight
    }
  }, [transcript])

  // useEffect(()=>{
  //   let authData = Cookies.get('authDetails')
  //   if(authData){
  //     setIsAuth(true)
  //   }
  //   else{
  //     setIsAuth(false)
  //     navigation('/login')
  //   }
  // },[])

useEffect(()=>{
  if(level !== ''){
handleStartInterview()
  }
  },[level])

  useEffect(()=>{
    if(chats.length > 1){
      chatBox.current.scrollTop = chatBox.current.scrollHeight
    }
  },[chats])

  return (
    <>
    <Suspense fallback = {<Loader dataInterpolation={(p) => `Loading... please wait`}/>}>
    <div className='h-screen w-screen overflow-y-hidden'>
{/* <div style={STYLES.area}>
        <button style={STYLES.speak}>
          {speak || load ? 'Running...' : 'Type message.'}
        </button>
      </div> */}

      <div className='chat-div'>
        <div className='chat-box' ref={chatBox}>
          {chats.map((chat) => {
            if (chat.who === "me") {
              return <p className={chat.msg === '' ? "" : chat.who}>
                {chat.msg}
              </p>
            } else {
              return <p className={chat.who}>
                {chat.msg}
              </p>
            }
          })}

          {(load == true || speak) && !playing ? <p style={{ padding: '5px', display: 'flex', alignItems: 'center' }}><Lottie animationData={LoadingAI} loop = {true} style={{height : '50px',width:'50px'}}/></p> : <></>}
          <ToastContainer
        position="top-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{zIndex:10000}}
      />
        </div>
      
      <div className='absolute bottom-1 right-2 z-[1000] flex flex-col w-[100%]'>
      {show && startInterview && !speak &&(
       <> 
       <div className='relative w-full py-4 px-6  h-80 overflow-y-auto bg-white rounded-lg shadow-md'>
        <div className='h-1/2 overflow-y-auto' onChange={e => setMsg(e.target.value)} ref={messageRef}>
          {msg}
        </div>
        {recordedAudio && confirmation && <div className='relative flex justify-end flex-col space-y-3 mt-6'>
          <div className='flex justify-center'>
            <audio src={URL.createObjectURL(recordedAudio)} controls controlsList='nodownload'></audio>
          </div>
          <div className='flex justify-center space-x-4 '>
          <div className='py-2 px-3 border border-slate-800 rounded-lg cursor-pointer hover:bg-slate-800 hover:text-white ease-in-out' onClick={handleCancle}>Cancel</div>
          <div className='py-2 px-3 border border-slate-800 rounded-lg cursor-pointer hover:bg-slate-800 hover:text-white ease-in-out' onClick={handleSubmit}>Submit</div>
          </div>
        </div>}
        <div className='flex flex-col items-center'>
          {!confirmation && <div className='bg-slate-800 rounded-full h-14 w-14 p-3 relative' style={{width : 'fit-content'}}>
            <img src={Mic} alt="Mic img" className='w-full h-full' />
            {recording && <div className='pulse-ring pulsate'></div>}
          </div>}
        <div className = 'flex space-x-4 relative items-end mt-5 w-full'>
        {!recording && !confirmation && <button className='text-white w-full bg-slate-800 rounded-md py-2 px-4 text-base font-semibold relative' onClick={startListening}>
            Start Recording
      </button>}
      {recording && !confirmation && <button className='text-white w-full bg-slate-800 rounded-md py-2 px-4 text-base font-semibold relative' onClick={stopListening}>
            Stop Recording
      </button>}
      {!recording && !confirmation  && <button className='text-white w-full bg-slate-800 rounded-md py-2 px-4 text-base font-semibold relative' onClick={()=>{getResponse('Stop Interview','stop_interview')}}>
            Stop Interview
      </button>}
        </div>
        </div>
       </div>
        </>
       )
}     {!startInterview && <button className='text-white relative bg-slate-800 rounded-md py-2 px-4 text-base font-semibold right-6 mb-4' onClick={
  async ()=>{
    setModalShow(true)
    // getResponse('Start Interview','start')
    // setStartInterview(true)
  }
  }>
    Start Interview
    </button>}

</div>
</div>

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlayThrough={playerReady}
      />
           <ReactMic
            record = {recording}
            onStop = {(recordedData)=>{
            onStop(recordedData)
            }}
            className = {'absolute hidden'}
            />
    <Canvas dpr={2} onCreated={(ctx)=>{
      ctx.gl.physicallyCorrectLights = true;
    }}>
      <OrthographicCamera
      makeDefault= {400}
      zoom={1500}
      position={[0,1.65,1]}
      />

      {/* <Suspense fallback = {null}> */}
        <Environment background = {false} files="../images/photo_studio_loft_hall_1k.hdr"/>
      {/* </Suspense> */}

      {/* <Suspense fallback={<CanvasLoader/>}> */}
          <Bg />
        {/* </Suspense> */}

        {/* <Suspense fallback={<CanvasLoader/>}> */}
          <Avatar
            avatar_url="/model.glb"
            speak={speak}
            setSpeak={setSpeak}
            text={text}
            setAudioSource={setAudioSource}
            playing={playing}
          />
        {/* </Suspense> */}
        {/* <Preload all/> */}
    </Canvas>
    {/* <Loader /> */}
    </div>
    </Suspense>
    

    {modalShow && <div className='fixed top-0 h-screen w-screen left-0 bottom-0 flex items-center justify-center p-10 right-0 bg-black bg-opacity-25 z-[1000]'>
      <div className='bg-white rounded-lg  w-1/2'>
        <div className='bg-slate-800 p-4 rounded-t-lg'>
          <p className='text-white text-lg font-semibold'>{language ? 'Select Level': 'Select the Language'}</p>
        </div>
        <div className='p-4'>
         {!language && <div className='grid grid-cols-5 gap-3 justify-center'>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer' 
            onClick={()=>{setLanguage('javascript')}}
            >
              <img src={JavascriptLogo} alt="Javascript Language" className='h-20 w-20' />
            </div>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{setLanguage('java')}}
            >
              <img src={JavaLogo} alt="Java Language" className='h-20 w-20' />
            </div>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{setLanguage('python')}}
            >
              <img src={PythonLogo} alt="Python Language" className='h-20 w-20' />
            </div>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{setLanguage('react')}}
            >
              <img src={ReactLogo} alt="React Language" className='h-20 w-20' />
            </div>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{setLanguage('sql')}}
            >
              <img src={SqlLogo} alt="Sql Language" className='h-20 w-20' />
            </div>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{setLanguage('c')}}
            >
              <img src={CLogo} alt="C Language" className='h-20 w-20' />
            </div>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{setLanguage('c++')}}
            >
              <img src={CPlusLogo} alt="C++ Language" className='h-20 w-20' />
            </div>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{setLanguage('c#')}}
            >
              <img src={CSharpLogo} alt="C# Language" className='h-20 w-20' />
            </div>
          </div> }

          {language && !level && <div className='grid grid-cols-4 gap-4'>
            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{
              setLevel('beginner')
              setModalShow(false)
            }}
            >
              <div className='text-lg text-slate-800 font-bold'>Beginner</div>
            </div>

            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={async ()=>{
              setLevel('intermediate')
              setModalShow(false)
          }}
            >
              <div className='text-lg text-slate-800 font-bold'>Intermediate</div>
            </div>

            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{
              setLevel('advanced')
              setModalShow(false)
            }}
            >
              <div className='text-lg text-slate-800 font-bold'>Advanced</div>
            </div>

            <div className='rounded-md border border-black bg-white p-2 flex justify-center items-center flex-col cursor-pointer'
            onClick={()=>{
              setLevel('expert')
              setModalShow(false)
            }}
            >
              <div className='text-lg text-slate-800 font-bold'>Expert</div>
            </div>
          </div> }
        </div>
        <div className='bg-slate-800 p-4 rounded-b-lg'>
        <button className='px-3 py-1 bg-red-600 border border-white text-white font-semibold text-base rounded-lg' onClick={()=>{
            setModalShow(false)
            setLanguage('')
            }}>Close</button>
        </div>
      </div>
    </div>}
    </>
  )
}

function Bg() {

  const texture = useTexture('/images/bg41.jpg');

  return (
    <mesh position={[0, 1.5, -4]} scale={[1.2, 1.2, 1.2]}>
      <planeGeometry/>
      <meshBasicMaterial map={texture} />
    </mesh>
  )

}

export default AI_Interview