import React from 'react'
import { TestimonialAvatar } from '../theme/images';
import { Swiper,SwiperSlide } from 'swiper/react'
import 'swiper/css';
import { Navigation,Autoplay } from 'swiper/modules';
const Testimonials = () => {
    const testimonialData = [
        {
            key : 1,
            name: "Ida d.",
            image:TestimonialAvatar,
            profession : 'Front-end developer',
            text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
        },
        {
            key : 1,
            name: "Ida d.",
            image:TestimonialAvatar,
            profession : 'Front-end developer',
            text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
        },
        {
            key : 1,
            name: "Ida d.",
            image:TestimonialAvatar,
            profession : 'Front-end developer',
            text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
        },
        {
            key : 1,
            name: "Ida d.",
            image:TestimonialAvatar,
            profession : 'Front-end developer',
            text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
        },
        {
            key : 1,
            name: "Ida d.",
            image:TestimonialAvatar,
            profession : 'Front-end developer',
            text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
        },
        {
            key : 1,
            name: "Ida d.",
            image:TestimonialAvatar,
            profession : 'Front-end developer',
            text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
        },
    ]
  return (
    <>
    <section className='pt-10 pb-16 xl:px-32 lg:px-28 md:px-24 sm:px-20 px-8 bg-white'>
        <div className="container">
        <h1 className='xl:text-5xl md:text-4xl sm:text-3xl text-2xl font-semibold text-inputInner mb-3'>Testimonials</h1>
        <div className='d-flex mb-5'>
        <p className='text-secondary xl:text-lg md:text-base text-sm'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>

        <div className="testimonial-slider w-full">
            <Swiper 
            slidesPerView={3}
            spaceBetween={30}
            navigation = {false}
            loop = {true}
            autoplay = {{delay: 2500,
                disableOnInteraction: false,}}
                breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    560: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
            modules={[Navigation,Autoplay]}
            >
                {testimonialData.map((item)=>{
                    return(
                        <SwiperSlide>
                    <div className="testimonial-card border border-input rounded-lg md:p-4 p-3">
                        <div className="flex ">
                            <img src={item.image} alt="" className='lg:h-16 lg:w-16 sm:w-12 sm:h-12 h-12 w-12' />
                            <div className='ms-4 space-y-1'>
                                <p className='text-inputInner md:text-lg text-base font-semibold'>{item.name}</p>
                                <p className='text-secondary md:text-base text-sm font-semibold'>{item.profession}</p>
                            </div>
                        </div>
                        <p className='text-secondary lg:text-base sm:text-sm text-sm font-normal mt-4'>{item.text}</p>
                    </div>
                </SwiperSlide>
                    )
                })}
                
            </Swiper>
        </div>
    </div>
    </section>
    </>
  )
}

export default Testimonials