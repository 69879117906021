import React, { useContext, useEffect, useState } from "react";
import { Logo, Google, Facebook, LinkedIn, LoginHero } from "../theme/images";
import InputBox from "../components/InputBox";
import Label from "../components/Label";
import { Regex } from "../theme/Regex";
import CheckBox from "../components/CheckBox";
import { NavLink, useNavigate,redirect } from "react-router-dom";
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css';
import ToastNotification from '../components/ToastNotification.jsx'
import AccountContext from "../Context/AccountContext";
import Lottie from "lottie-react";
import Loader from '../lottie/loading animation.json'


const Login = () => {
  const [email, setEmail] = useState("");
  const [password,setPassword] = useState('')
  const [emailBorder,setEmailBorder] = useState('border border-input')
  const [validEmail,setValidEmail] = useState(false)
  const [iconToggle,setIconToggle] = useState(false)
  const [checkboxValue,setCheckboxValue] = useState(false)
  const navigation = useNavigate()
  const {authenticate,getSession} = useContext(AccountContext)
  const [loading,setLoading] = useState(false)
  const [isAuth,setIsAuth] = useState(false)

  function signInCheck (mail:String,pass:String){
    if(mail.trim() === '' || pass.trim() === '' || Regex.EmailRegex.test(email) === false || checkboxValue === false){
        return 'bg-[#EAECF0] text-secondary'
    }

    else{
        return 'bg-[#4984E3] text-white'
    }
  }

  function handleBlur (){
    if(Regex.EmailRegex.test(email) === false && email.trim() !== ''){
            setEmailBorder('border border-red-400')
            setValidEmail(true)
    }

    else {
        setEmailBorder('border border-input')
    }
  }

  function handleFocus (){
    setValidEmail(false)
  }

  const handleLogin = ()=>{
    setLoading(true)
    authenticate(email,password)
    .then((data:any)=>{
      console.log('Login successfully',data)
      if(data){
        getSession()
        .then((response:any)=>{
          console.log(response)
          if(response){
            const session = {name :response?.name}
            Cookies.set('authDetails',JSON.stringify(session))
            navigation('/')
          }
          setLoading(false)
        })
        .catch((err : any)=>{
          console.log(err)
          setLoading(false)
        }) 
      }
      else{
        setLoading(false)
      }
    })
    .catch((err : any)=>{
      toast.error(err.message)
      setLoading(false)
    })
  }

  const handleGoogleSignIn = async () => {
    
  };

  const handleFacebookSignIn = async ()=>{
    
  }

  useEffect(()=>{
    let authData = Cookies.get('authDetails')
    if(authData){
      setIsAuth(true)
    }
    else{
      setIsAuth(false)
    }
  },[])

  if(isAuth === true){
    navigation('/')
    return (
      <>
      <p>Already Logged In</p>
      </>
    )
  }
  else{
  return (
    <>
      <section className="h-screen w-screen">
      {loading && <Lottie  animationData={Loader} loop={true} style={{position :'absolute',backgroundColor:'rgba(17, 24, 29, 0.7)',height:'100%',width:'100%',zIndex:'200'}}/>}
          <div className="grid grid-cols-1 lg:grid-cols-2 place-items-center h-screen w-screen">
            <div className="lg:pb-3 flex justify-center">
              <div className="login-img py-16 lg:py-40 flex flex-col">
                <img src={Logo} alt="" className="flex self-start" />
                <h1 className="text-3xl font-semibold mt-5 tracking-wide">
                  Log in to your account
                </h1>
                <div className="text-base text-secondary mt-2">
                  Welcome back! Please enter your details.
                </div>
                <div className="login-input mt-7">
                  <Label
                    className="block mb-1 text-sm font-medium text-inputText dark:text-white"
                    name="Email"
                  />
                  <InputBox
                    className={`input bg-white ${emailBorder} font-medium  text-sm text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5`}
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                  
                  {validEmail === true && <div className="mt-2 text-sm text-red-500">Enter a valid Email address</div>}
                </div>
                <div className="login-input mt-4">
                  <Label
                    className="block mb-1 text-sm font-medium text-inputText dark:text-white"
                    name="Password"
                  />
                  <InputBox
                    className="input bg-white border border-input font-medium  text-sm text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 ps-2.5 pe-8"
                    iconClass={`${iconToggle === true ? 'bi bi-eye-slash' : 'bi bi-eye'} font-bold text-md absolute right-4 bottom-2 px-1 cursor-pointer`}
                    iconClick={()=>{
                        setIconToggle(!iconToggle)
                    }}
                    type={`${iconToggle === true ? 'text' : 'password'}`}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e)=> setPassword(e.target.value)}
                  />
                </div>

                <div className="md:w-96 sm:w-80 w-64 mt-4 grid grid-cols-2">
                  <div className="flex items-center">
                    <CheckBox
                    checked={checkboxValue}
                    setValue={()=>{setCheckboxValue(!checkboxValue)}}
                    />
                    <Label
                      className="block ps-1 pt-1 text-sm font-medium text-inputText dark:text-white cursor-pointer"
                      name="Remember me"
                      onClick={()=>{setCheckboxValue(!checkboxValue)}}
                    />
                  </div>
                  <div className="items-center flex justify-end text-sm cursor-pointer text-[#4984E3] font-semibold">
                    <NavLink to={'/forgotPassword'} className="items-center flex justify-end text-sm cursor-pointer text-[#4984E3] font-semibold">Forgot Password</NavLink>
                  </div>
                </div>

                <div 
                className={`w-full mt-4 py-2 text-center text-md font-semibold cursor-pointer ${signInCheck(email,password)} rounded-md`}
                onClick={()=>{
                  if(email.trim() === '' || password.trim() === ''){
                    toast.error('Please fill all the details !')
                  }

                //   else if(email !== 'admin@admin.com' || password !== '12345'){
                //     toast.error('Invalid email or password!')
                //   }
                  
                //  else if(checkboxValue === false){
                //     toast.error('Please check the checkbox !')
                //   }

                  else{
                    // const user : User = {name : 'Admin', email : email}
                    // const login = {email : email, password : password, checkbox : checkboxValue}
                    // Cookies.set('user',JSON.stringify(user))
                    // Cookies.set('loginDetails',JSON.stringify(login))
                    // navigation('/')
                    handleLogin()
                  }

                }}
                >
                  Sign in
                </div>
                <ToastNotification/>
                <div className="grid grid-cols-3 mt-3 gap-4">
                  <div className="py-1.5 rounded-lg border border-input flex w-full justify-center items-center cursor-pointer" onClick={handleGoogleSignIn}>
                    <img src={Google} alt="Google" />
                  </div>

                  <div className="py-1.5  rounded-lg border border-input flex w-full justify-center items-center cursor-pointer" onClick={handleFacebookSignIn}>
                    <img src={Facebook} alt="Facebook" />
                  </div>

                  <div className="py-1.5  rounded-lg border border-input flex w-full justify-center items-center cursor-pointer">
                    <img src={LinkedIn} alt="Facebook" />
                  </div>
                </div>

                <div className="mt-6 text-sm w-full text-secondary text-center">
                  No employer account yet?{" "}
                  <NavLink to={'/signUp'} className="text-[#4984E3] font-semibold">Sign Up</NavLink>
                </div>
              </div>
            </div>
            <div className="w-full h-full bg-focusRing items-center justify-center hidden lg:flex">
              <img src={LoginHero} alt="" />
            </div>
          </div>
       
      </section>
    </>
  );
   }
};

export default Login;
