import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import OtpInput from 'react-otp-input'
import Label from "../components/Label";
import { OtpHero } from "../theme/images";
import { Regex } from "../theme/Regex";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate} from 'react-router-dom';

const Otp: React.FC = () => {
  const [otp, setOtp] = useState('')
  const navigation = useNavigate()

  const handleSubmit = ()=>{
    if(Regex.Otp.test(otp) == false){
        toast.error('Please give a 6 digit OTP!')
    }
    else{
        navigation('/newPassword')
    }
  }
  
  return (
    <>
      <div className="h-screen w-screen">
        <div className="grid lg:grid-cols-2 grid-cols-1 place-items-center h-full w-full">
          <div className="flex flex-col md:px-10 sm:px-7 px-5">
            <div className="md:text-3xl text-lg font-semibold tracking-wide">
              Enter OTP
            </div>
            <div className="md:text-base sm:text-sm text-xs text-secondary mt-2 md:w-96 w-full">
              Please enter the OTP sent to your email address.
            </div>
            <div className="mt-7">
              <Label
                className="block mb-1 md:text-base sm:text-sm text-xs font-medium text-inputText dark:text-white"
                name="OTP"
              />
              <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} className="ps-0 pr-0 text-center border border-inputInner rounded outline-none font-semibold focus:border-primary focus:text-primary text-inputInner transition" style={{height : '3rem', width : '3rem'}} />}
    />
    <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

                <div 
                className={`w-full mt-6 py-2 text-center text-sm md:text-base font-semibold cursor-pointer bg-[#4984E3] text-white rounded-md`}
                onClick={()=>{handleSubmit()}}
                >
                  Submit OTP
                </div>
            </div>
          </div>
          <div className="w-full h-full bg-focusRing items-center justify-center hidden lg:flex">
            <img src={OtpHero} alt="" className="aspect-square" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
