import React, {useState} from 'react'
import ResultContext from '../Context/AI_Result_Context'

export const ResultData = (props) => {
const [dataArray,setDataArray] = useState([])

const addData = (data)=>{
    setDataArray([...dataArray, data]);
}

  return (
    <ResultContext.Provider value={{dataArray, addData}}>
        {props.children}
    </ResultContext.Provider>
  )
}
