import React from 'react'

interface labelProps{
    className : string,
    name : string,
    onClick ?: ()=> void
}
const Label : React.FC <labelProps> = (props) => {
  return (
    <label className={props.className} onClick={props.onClick}>{props.name}</label>
  )
}

export default Label