import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Label from '../components/Label'
import InputBox from '../components/InputBox'
import CheckBox from '../components/CheckBox'
import { ContactHero } from '../theme/images'
import PhoneInput,{formatPhoneNumber,formatPhoneNumberIntl,isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import { Regex } from '../theme/Regex'
import { ToastContainer,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ToastNotification from '../components/ToastNotification.jsx'
import Transitions from '../components/Transitions'
import AccountState from '../States/AccountState'

const Contact = () => {
    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [email,setEmail] = useState('')
    const [number,setNumber] = useState('')
    const [message,setMessage] = useState('')
    const [isCheck,setIsCheck] = useState(false)
    const [validationErrorNumber, setValidationErrorNumber] = useState<boolean>(false);
    const [validationErrorFirstName, setValidationErrorFirstName] = useState<boolean>(false);
    const [validationErrorLastName, setValidationErrorLastName] = useState<boolean>(false);
    const [validationErrorEmail, setValidationErrorEmail] = useState<boolean>(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState<boolean>(false);

    const handleOnChange = (value:string) => {
        setNumber(value);
      };

      const handleOnBlur = (value:string)=>{
        if(value==='number'){
          if(!isValidPhoneNumber(number) || !formatPhoneNumberIntl(number)){
            setValidationErrorNumber(true)
          }
          else{
            setValidationErrorNumber(false)
          }
        }

        if(value === 'firstName'){
          if(firstName.trim() === ''){
            setValidationErrorFirstName(true)
          }
          else{
            setValidationErrorFirstName(false)
        }
       
        }

      if(value === 'lastName'){
        if(lastName.trim() === ''){
          setValidationErrorLastName(true)
        }
        else{
          setValidationErrorLastName(false)
      }
     
      }

      if(value === 'email'){
        if(email.trim() === '' || Regex.EmailRegex.test(email) === false){
          setValidationErrorEmail(true)
        }
        else{
          setValidationErrorEmail(false)
      }
     
    }

    if(value === 'message'){
      if(message.trim() === ''){
        setValidationErrorMessage(true)
      }
      else{
        setValidationErrorMessage(false)
    }
   
    }
    }
      
      const handleInputFocus =  (value:string) => {
        if(value === 'number'){
        setValidationErrorNumber(false)
      }
      if(value === 'email'){
        setValidationErrorEmail(false)
      }
      if(value === 'firstName'){
        setValidationErrorFirstName(false)
      }
      if(value === 'lastName'){
        setValidationErrorLastName(false)
      }
      if(value === 'message'){
        setValidationErrorMessage(false)
      }
        
      }

      const submit = ()=>{
        if(firstName.trim() === '' || lastName.trim() === '' || email.trim() === '' || message.trim() === '' || isCheck === false){
          toast.error('Please fill all the details !')
        }
        else{
          toast.success('Message Sent.')
        }
      }

      const selectedCountryDialCode = "+1"
  return (
    <>
    <AccountState>
    <Navbar/>
    </AccountState>
    <Transitions>
    <section className='h-screen w-screen py-32 xl:px-56 lg:px-0 md:px-40 sm:px-28 px-12'>
        <div className="grid lg:grid-cols-2 grid-cols-1 xl:gap-0 lg:gap-6 lg:place-items-center">
            <div className="flex flex-col py-5 h-full lg:w-auto md:w-full">
            <div className='lg:text-4xl md:text-3xl text-lg font-semibold tracking-wide'>Get in touch</div>
            <div className="lg:text-lg md:text-base sm:text-sm text-xs text-secondary mt-2 md:w-96 w-full">
                Our friendly team would love to hear from you.
                </div>

                <div className="mt-7 space-y-7">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
                        <div className='w-full'>
                            <Label
                            className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
                            name="First Name"
                            />
                            <InputBox
                            className={`input bg-white border ${validationErrorFirstName ? 'border-red-400':'border-input'} font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 pe-8`}
                            placeholder='First name'
                            type='text'
                            value={firstName}
                            onBlur={()=>{handleOnBlur('firstName')}}
                            onChange={(e)=>{setFirstName(e.target.value)}}
                            onFocus={()=>{handleInputFocus('firstName')}}
                            />
                            {validationErrorFirstName && (
                        <span className='mt-2 text-sm text-red-500'>Please enter your first name</span>
                          )}
                        </div>
                        <div className='w-full'>
                            <Label
                            className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
                            name="Last Name"
                            />
                            <InputBox
                            className={`input bg-white border ${validationErrorLastName ? 'border-red-400':'border-input'} font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 pe-8`}
                            placeholder='Last name'
                            type='text'
                            value={lastName}
                            onChange={(e)=>{setLastName(e.target.value)}}
                            onBlur={()=>{handleOnBlur('lastName')}}
                            onFocus={()=>{handleInputFocus('lastName')}}
                            />
                            {validationErrorLastName && (
                        <span className='mt-2 text-sm text-red-500'>Please enter your last name</span>
                          )}
                        </div>
                    </div>
                    <div className="input">
                        <Label 
                        className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
                        name="Email"
                        />
                        <InputBox
                        className={`input bg-white border ${validationErrorEmail ? 'border-red-400':'border-input'} font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 pe-8`}
                        placeholder='you@company.com'
                        type='email'
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}
                        onFocus={()=>{handleInputFocus('email')}}
                        onBlur={()=>{handleOnBlur('email')}}
                        />
                        {validationErrorEmail && (
                        <span className='mt-2 text-sm text-red-500'>Invalid email</span>
                          )}
                    </div>
                    
                    <div className="input">
                        <Label 
                        className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
                        name="Phone number"
                        />
                        <PhoneInput
                        international
                        defaultCountry={'US'} 
                        country={selectedCountryDialCode}
                        onChange={handleOnChange}
                        onBlur={()=>{handleOnBlur('number')}}
                        onFocus={()=>{handleInputFocus('number')}}
                        countryCallingCodeEditable = {false}
                        value={number}
                        />
                        {validationErrorNumber && (
                        <span className='mt-2 text-sm text-red-500'>Invalid phone number</span>
                          )}
                    </div>

                    <div className="input">
                        <Label 
                        className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
                        name="Message"
                        />
                        
                        <textarea name="" id="" cols={30} rows={5}
                        className={`input bg-white border ${validationErrorMessage ? 'border-red-400':'border-input'} font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 pe-8`}
                        value={message}
                        onChange={(e)=>{setMessage(e.target.value)}}
                        onBlur={()=>{handleOnBlur('message')}}
                        onFocus={()=>{handleInputFocus('message')}}
                        ></textarea>

                      {validationErrorMessage && (
                        <span className='mt-2 text-sm text-red-500'>Please enter your message</span>
                          )}
                    </div>

                    <div className='flex items-center space-x-2'>
                        <CheckBox
                        checked={isCheck}
                        setValue={()=>setIsCheck(!isCheck)}
                        />
                        <div className='text-secondary font-normal sm:text-sm text-xs mt-1'>You agree to our friendly <span className='underline font-medium'>privacy policy</span>.</div>
                    </div>

                    <button onClick={()=>{submit()}} className='w-full text-center text-white custom custom-button-bg rounded-lg sm:text-sm text-xs py-2'>Send Message</button>
                    <ToastNotification/>
                </div>
            </div>

            <div className='lg:flex hidden justify-center  items-center'>
                <img src={ContactHero} alt="" className={'h-[80vh]'} />
            </div>
        </div>
    </section>
    </Transitions>
    </>
  )
}

export default Contact