import React, { useEffect } from 'react';
import './App.css';
import Login from './screens/Login';
import { BrowserRouter, Route, RouterProvider,Routes,Outlet, } from 'react-router-dom';
import ForgotPassword from './screens/ForgotPassword';
import GetEmail from './screens/GetEmail';
import NewPassword from './screens/NewPassword';
import SignUp from './screens/SignUp';
import Dashboard from './pages/Dashboard';
import FindJob from './pages/FindJob';
import About from './pages/About';
import Contact from './pages/Contact';
import ErrorPage from './pages/404Page';
import Dummy from './components/Dummy';
import { AnimatePresence } from 'framer-motion';
import Transitions from './components/Transitions';
import CompanyProfile from './pages/CompanyProfile';
import Interview from './components/Interview';
import AccountState from './States/AccountState';
import { ResultData } from './States/ResultData';
import AI_Interview from './components/AiInterview';
import DummyTextToSpeech from './pages/DummyTextToSpeech';
import Result from './pages/result';
import Otp from './screens/Otp';
import StartInterview from './pages/StartInterview';

function App() {
  
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
  
    if (params.has('interview_id') && !window.location.pathname.includes('start-interview')) {
      window.location.href = `${window.location.origin}/start-interview${window.location.search}`;
    }
  }, []);
  
  return (
   <>
   <BrowserRouter>
   <Routes>
    <Route element = {<Transitions><Outlet/></Transitions>}></Route>
    <Route path='/login' element = {<AccountState><Login/></AccountState>}/>
    <Route path='/forgotPassword' element = {<ForgotPassword/>}/>
    <Route path='/getEmail' element = {<GetEmail/>}/>
    <Route path='/otp' element = {<Otp/>}/>
    <Route path='/newPassword' element = {<NewPassword/>}/>
    <Route path='/signUp' element = {<AccountState><SignUp/></AccountState>} />
    <Route path='/' element = {<Dashboard/>}/>
    <Route path='/find-job' element = {<FindJob/>} />
    <Route path='/about-us' element = {<About/>}/>
    <Route path='/contact-us' element = {<Contact/>}/>
    {/* <Route path='/dummy' element = {<Dummy/>}/> */}
    <Route path='/company-profile' element = {<CompanyProfile/>}/>
    {/* <Route path='/interview' element = {<Interview/>}/> */}
    <Route path='/ai-interview' element = {<ResultData><AI_Interview/></ResultData>}/>
    <Route path='/dummy' element = {<DummyTextToSpeech/>}/>
    <Route path='/ai_result' element = {<ResultData><Result/></ResultData>}/>
    <Route path='/start-interview' element = {<StartInterview/>}/>
    
    {/* <Route element={<ErrorPage/>} path='*'/> */}
   </Routes>
   </BrowserRouter>
   </>
  );
}

export default App;
