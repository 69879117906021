import React,{useContext, useEffect, useState} from 'react'
import { resultDummyData } from '../helpers/dummyData'
import ResultContext from '../Context/AI_Result_Context';
import { useNavigate } from 'react-router-dom';
import Transitions from '../components/Transitions';

const Result = () => {
    const {dataArray} = useContext(ResultContext);
    const navigation = useNavigate();
    console.log('Result Data Array---->',dataArray)

    useEffect(() => {
        console.log('if log-->')
        if (dataArray.length === 0) {
          navigation('/');
        }
      },[]);
      const [scoreTooltip, setScoreTooltip] = useState(Array(resultDummyData.length).fill(false));
  
    return (
      <>
      <Transitions>
        <div className='w-full py-6 bg-white shadow-md flex justify-center space-x-4 items-center'>
          <div className='text-center text-primary text-4xl font-bold'>AI RESULT</div>
          <div className='px-4 py-2 bg-primary text-white cursor-pointer rounded-lg' onClick={()=>{navigation('/')}}>Dashboard</div>
        </div>
        <div className="grid grid-cols-5">
          <div className='mx-28 col-span-5 mt-10'>
            <div className='grid grid-cols-3 gap-6'>
              {dataArray.length !== 0 &&  dataArray[0].map((item:any, index:any) => (
                <div key={index} className='p-4 rounded-md shadow-md space-y-2'>
                  <div className='text-sm text-black font-medium flex space-x-4'>
                    <span className=''>Q{index + 1}:</span> <span>{item.question}</span>
                  </div>
                  <div className='text-sm font-medium flex space-x-3 text-gray-600'>
                    <span>Ans:</span> <span>{item.answer}</span>
                  </div>
                  <div className='flex space-x-3 items-center'>
                    <div className='text-sm font-medium'>Score:</div>
                    <div
                      className='w-full h-1 rounded bg-gray-300 relative cursor-pointer'
                      onMouseEnter={() => {
                        const newTooltipState = [...scoreTooltip];
                        newTooltipState[index] = true;
                        setScoreTooltip(newTooltipState);
                      }}
                      onMouseLeave={() => {
                        const newTooltipState = [...scoreTooltip];
                        newTooltipState[index] = false;
                        setScoreTooltip(newTooltipState);
                      }}
                    >
                      <div
                        className={`rounded h-1 ${
                          item.score < 4 ? 'bg-red-600' : item.score < 8 ? 'bg-yellow-400' : 'bg-green-600'
                        }`}
                        style={{ width: item.score * 10 + '%' }}
                      ></div>
                      <div
                        className={`absolute rounded tooltip-transition ${
                          scoreTooltip[index] ? 'onHover' : 'onLeave'
                        } py-1 px-3 bg-white border ${
                            item.score < 4 ? 'border-red-600' : item.score < 8 ? 'border-yellow-400' : 'border-green-600'
                          } text-xs top-0 left-[45%] translate-x-[-50%]`}
                      >
                        {item.score}0%
                      </div>
                    </div>
                  </div>
                  <div className='text-sm text-black font-medium flex space-x-4'>
                    <span className=''>FeedBack:</span> <span>{item.feedback}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        </Transitions>
      </>
    );
  };
  
  export default Result;