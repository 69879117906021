import React from 'react'
import { NavLink } from 'react-router-dom'

const HeroSection = () => {
  return (
    <section className={`w-full bg-hero-pattern bg-cover bg-bottom bg-no-repeat lg:h-screen md:h-[80vh] h-[70vh]`}>
        <h1 className='text-center md:pt-56 pt-40 lg:text-6xl md:text-4xl sm:text-3xl text-2xl font-semibold text-inputInner'>Unlock Your Potential in</h1>
        <h1 className='text-center lg:text-6xl md:text-4xl sm:text-3xl text-2xl font-semibold text-inputInner'>the Digital World</h1>
        <p className='text-center md:text-lg sm:text-base text-sm text-secondary mt-5 px-10'>Connecting Businesses with Top Remote Developers for Exceptional Results</p>
        <div className='text-center mt-12'>
        <NavLink to={'/find-job'} type="button" className="text-white custom-button-bg font-semibold rounded-lg text-sm px-6 py-3 text-center">Browse Jobs</NavLink>
        </div>
    </section>
  )
}

export default HeroSection