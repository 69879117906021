import React from 'react'
import { motion } from 'framer-motion'

const animationConfiguration = {
    initial: { opacity: 0,y:10},
    animate: { opacity: 1,y:0},
    exit: { opacity: 0,y:-10},
};
const Transitions = ({children}) => {
  return (
    <motion.div
    variants={animationConfiguration}
    initial = {'initial'}
    animate = {'animate'}
    exit={'exit'}
    transition={{duration : 0.3,ease :'backIn'}}
    >
      {children}
    </motion.div>
  )
}

export default Transitions