import React from 'react'
import { CheckIconWhite } from '../theme/images'
import { NavLink } from 'react-router-dom'

interface findJobProps {
    isAuth ?: boolean 
}
const FindJob : React.FC<findJobProps> = (props) => { 
  return (
    <>
    <section className='w-full bg-primary md:py-20 py-16'>
        <div className="flex flex-col justify-center items-center">
            <h1 className='lg:text-4xl text-white font-semibold md:text-3xl text-2xl'>Find your perfect job</h1>

            <div className="mt-10 grid grid-cols-3 gap-3">
                <div className='flex items-center justify-center'>
                    <img src={CheckIconWhite} className='md:h-5 md:w-5 h-4 w-4' alt="" />
                    <p className='text-white font-normal md:text-lg sm:text-base text-xs ms-2'>Free to use</p>
                </div>

                <div className='flex items-center'>
                    <img src={CheckIconWhite} className='h-5 w-5' alt="" />
                    <p className='text-white font-normal md:text-lg sm:text-base text-xs ms-2'>Easy to setup</p>
                </div>

                <div className='flex items-center'>
                    <img src={CheckIconWhite} className='h-5 w-5' alt="" />
                    <p className='text-white font-normal md:text-lg sm:text-base text-xs ms-2'>10+ job boards</p>
                </div>

            </div>

            {props.isAuth !== true && <NavLink to={'/signUp'} type="button" className="text-primary bg-white mt-12 font-semibold rounded-lg text-sm px-4 py-2 text-center lg:w-44 sm:w-40 w-36">SignUp Now</NavLink>}
        </div>
    </section>
    </>
  )
}

export default FindJob