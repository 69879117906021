import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export default function ToastNotification() {
  const contextClass = {
    success: "bg-white border border-green-400",
    error: "bg-white border border-red-400",
    info: "bg-gray-600",
    warning: "bg-orange-400",
    default: "bg-indigo-600",
    dark: "bg-white-600 font-gray-300",
  };

  const bodyClass = {
    success: "text-green-500",
    error: "text-red-500",
    info: "bg-gray-600",
    warning: "bg-orange-400",
    default: "text-indigo-600",
    dark: "bg-white-600 font-gray-300",
  }
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          toastClassName={({type})=> contextClass[type || 'default'] +
          " relative flex py-2 px-3 min-h-10  rounded-md justify-between overflow-hidden cursor-pointer shadow-md md:mb-3 mt-3 mx-2"
  }
  bodyClassName={({type}) => bodyClass[type || 'default'] + " flex"}
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }