import React from "react";
import AccountContext from "../Context/AccountContext";
import userPool from "./userPool";
import { AuthenticationDetails, CognitoUser, } from "amazon-cognito-identity-js";

const AccountState = (props)=>{

    const logout = async ()=>{
        return await new Promise((resolve,reject)=>{
            const user = userPool.getCurrentUser()
        if(user){
            user.signOut()
            resolve(user)
        }
        else{
            reject()
        }
        })
    }

    const getSession = async () => {
        return new Promise(async (resolve, reject) => {
          const user = userPool.getCurrentUser();
      
          if (user) {
            user.getSession(async (err, session) => {
              if (err) {
                reject(err);
              } else {
                try {
                  const attributes = await getUserAttributes(user);
                  resolve({ user, ...session, ...attributes });
                } catch (attributesError) {
                  reject(attributesError);
                }
              }
            });
          } else {
            reject();
          }
        });
     };
      
      const getUserAttributes = (user) => {
        return new Promise((resolve, reject) => {
          user.getUserAttributes((err, attributes) => {
            if (err) {
              reject(err);
            } else {
              const results = {};
              for (let attribute of attributes) {
                const { Name, Value } = attribute;
                results[Name] = Value;
              }
              resolve(results);
            }
          });
        });
      };
      

    const signUp = async (email,name,password)=>{
        return await new Promise ((resolve,reject)=>{
            var attributeList = []

            var userName = {
                Name : 'name',
                Value : name
            }

            attributeList.push(userName)

            userPool.signUp(email,password,attributeList,null,(err,data)=>{
                if(err){
                    console.log('Failed to register',err.message)
                    reject(err)
                }else{
                    console.log("Account created successfully!",data)
                    resolve(data)
                }
            })
        })
    }

    const authenticate = async (Username,Password)=>{
        return await new Promise((resolve,reject)=>{
            const user = new  CognitoUser({
                Username,
                Pool : userPool,
            })
            const authDetails = new AuthenticationDetails({
                Username,
                Password
            })
            user.authenticateUser(authDetails,{
                onSuccess : (data)=>{
                    console.log('Login Success',data)
                    resolve(data)
                },

                onFailure : (err)=>{
                    console.log('Failure-->',err)
                    reject(err)
                },

                newPasswordRequired : (data)=>{
                    console.log('New Password Required',data)
                }
            })
        })
    }

    return(
        <AccountContext.Provider value={{signUp,authenticate,getSession,logout}}>
            {props.children}
        </AccountContext.Provider>
    )
}

export default AccountState