//@ts-nocheck
import React, { useEffect, useState, Fragment, useContext } from "react";
import { Logo } from "../theme/images";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Menu, Transition } from "@headlessui/react";
import AccountState from "../States/AccountState";
import AccountContext from "../Context/AccountContext";
import Lottie from "lottie-react";
import Loader from "../lottie/loading animation.json";

interface NavbarProps {
  setAuth?: any;
  setLoader?: any;
}

const Navbar: React.FC<NavbarProps> = (props) => {
  const location = useLocation();
  const [navToggle, setNavToggle] = useState(false);
  const [toggleClass, setToggleClass] = useState("translate-x-[0%]");
  const [userData, setUserData] = useState(false);
  const [sessionData, setSessionData] = useState("");
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const { getSession, logout } = useContext(AccountContext);

  useEffect(() => {
    const authData = Cookies.get("authDetails");
    if (authData !== undefined) {
      const authName = JSON.parse(authData);
      setSessionData(authName.name);
      setUserData(true);
    } else {
      setUserData(false);
    }
  }, []);

  const logoutSession = () => {
    props.setLoader(true);
    logout()
      .then((data: any) => {
        console.log("Logged Out Successfully", data);
        Cookies.remove("authDetails");
        setTimeout(() => {
          setUserData(false);
          setSessionData("");
          props.setLoader(false);
          navigation("/");
          props.setAuth(false);
        }, 1000);
      })
      .catch((err: any) => {
        console.log("Error", err);
        setTimeout(() => {
          props.setLoader(false);
        }, 1000);
      });
  };

  return (
    <>
      <nav className="bg-white  fixed w-full z-20 top-0 left-0 border-b border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center  mx-auto px-4 py-3 relative shadow-sm">
          <a
            href="#"
            className="flex justify-center text-center md:justify-start items-center"
          >
            <img src={Logo} className="md:mr-3 mr-0 h-7" alt="Job AI" />
          </a>

          <button
            type="button"
            className="ms-auto block items-center p-2 me-3 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={() => {
              setNavToggle(!navToggle);
              setToggleClass("translate-x-[-100%]");
            }}
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto  md:mr-auto ms-20"
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-[#F2F4F7] rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
              <li>
                <NavLink
                  to={"/"}
                  className={`block py-2 pl-3 pr-4 lg:text-base text-sm font-normal bg-blue-700 rounded md:bg-transparent ${
                    location.pathname === "/"
                      ? "md:text-primary font-semibold"
                      : "text-secondary"
                  } md:p-0 md:dark:text-blue-500`}
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/find-job"}
                  className={`block lg:text-base text-sm  py-2 pl-3 pr-4 font-normal ${
                    location.pathname === "/find-job"
                      ? "md:text-primary font-semibold"
                      : "text-secondary"
                  } rounded  md:hover:text-primary md:p-0`}
                >
                  Find Job
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/about-us"}
                  className={`block lg:text-base text-sm  py-2 pl-3 pr-4 font-normal ${
                    location.pathname === "/about-us"
                      ? "md:text-primary font-semibold"
                      : "text-secondary"
                  } rounded  md:hover:text-primary md:p-0`}
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/contact-us"}
                  className={`block lg:text-base text-sm  py-2 pl-3 pr-4 font-normal ${
                    location.pathname === "/contact-us"
                      ? "md:text-primary font-semibold"
                      : "text-secondary"
                  } rounded  md:hover:text-primary md:p-0`}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>

          {userData && (
            <div className="md:flex items-center space-x-4 flex-wrap hidden">
              <div className="text-secondary md:text-lg text-base font-extrabold cursor-pointer">
                <i className="bi bi-bell" style={{ strokeWidth: "20px" }}></i>
              </div>
              <div className="text-secondary md:text-xl text-base font-extrabold cursor-pointer">
                <i className="bi bi-envelope relative">
                  <span className="bg-primary text-white absolute top-[-0.2rem] left-3 right-0 h-4 w-4 rounded-full flex items-center justify-center text-xs">
                    5
                  </span>
                </i>
              </div>

              <div className="h-8 w-8 bg-[#E1EAFB] text-primary flex items-center justify-center rounded-full font-medium cursor-pointer">
                AI
              </div>
              <Menu>
                <Menu.Button
                  className={
                    "text-secondary font-normal text-sm flex items-center"
                  }
                >
                  <span>{sessionData}</span>{" "}
                  <span className="bi bi-chevron-down ms-1 text-xs self-center"></span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className={
                      "absolute border border-primary right-10 top-14 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
                    }
                  >
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? " text-inputInner" : "text-inputInner"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            logoutSession();
                          }}
                        >
                          <i className="bi bi-box-arrow-left md:text-sm text-sm text-inputInner"></i>
                          <span className="ms-2">Logout</span>
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          )}

          {userData ? (
            ""
          ) : (
            <NavLink
              to={"/login"}
              className="md:block hidden py-2 pl-3 mr-10 font-semibold text-inputText rounded hover:bg-gray-100 md:hover:bg-transparent md:p-0 cursor-pointer"
            >
              Login
            </NavLink>
          )}
          {userData === false && (
            <NavLink
              to={"/signUp"}
              type="button"
              className="text-white custom-button-bg md:block hidden font-semibold rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 "
            >
              Sign Up
            </NavLink>
          )}

          <div
            className={`h-[100vh] z-50 bg-white md:hidden sm:w-[50%] w-[70%] fixed ${toggleClass} top-0 left-[100%] shadow-md transition duration-300 ease-in-out`}
          >
            <div className="p-6 z-[54] bg-white h-full w-full">
              <i
                className="bi bi-x-circle-fill text-primary text-2xl"
                onClick={() => setToggleClass("translate-x-[0%]")}
              ></i>

              {userData && (
                <div className="flex items-center justify-center mt-4 space-x-8 flex-wrap">
                  <div className="text-secondary md:text-lg text-xl font-extrabold cursor-pointer">
                    <i className="bi bi-bell"></i>
                  </div>
                  <div className="text-secondary md:text-xl text-xl font-extrabold cursor-pointer">
                    <i className="bi bi-envelope relative">
                      <span className="bg-primary text-white absolute top-[-0.2rem] left-3 right-0 h-4 w-4 rounded-full flex items-center justify-center text-xs">
                        5
                      </span>
                    </i>
                  </div>

                  <div className="h-10 w-10 bg-[#E1EAFB] text-primary flex items-center justify-center rounded-full font-medium cursor-pointer">
                    AI
                  </div>
                </div>
              )}

              <div className="nav-menu py-8">
                <ul className="space-y-4">
                  <NavLink
                    to={"/"}
                    className={`py-2 px-4 border border-primary  rounded-lg flex items-center ${
                      location.pathname === "/" ? "bg-primary" : ""
                    }`}
                  >
                    <i
                      className={`bi bi-house-door text-lg ${
                        location.pathname === "/"
                          ? "text-white font-semibold"
                          : "text-primary"
                      } me-2`}
                    ></i>
                    <div
                      className={`${
                        location.pathname === "/"
                          ? "text-white font-semibold"
                          : "text-primary"
                      } font-medium text-sm`}
                    >
                      Home
                    </div>
                  </NavLink>

                  <NavLink
                    to={"/find-job"}
                    className={`py-2 px-4 border border-primary rounded-lg flex items-center ${
                      location.pathname === "/find-job" ? "bg-primary" : ""
                    }`}
                  >
                    <i
                      className={`bi bi-search text-lg ${
                        location.pathname === "/find-job"
                          ? "text-white font-semibold"
                          : "text-primary"
                      } me-2`}
                    ></i>
                    <div
                      className={`${
                        location.pathname === "/find-job"
                          ? "text-white font-semibold"
                          : "text-primary"
                      } font-medium sm:text-sm text-sm`}
                    >
                      Find Job
                    </div>
                  </NavLink>

                  <NavLink
                    to={"/about-us"}
                    className={`py-2 px-4 border border-primary rounded-lg flex items-center ${
                      location.pathname === "/about-us" ? "bg-primary" : ""
                    }`}
                  >
                    <i
                      className={`bi bi-info-circle text-lg ${
                        location.pathname === "/about-us"
                          ? "text-white font-semibold"
                          : "text-primary"
                      } me-2`}
                    ></i>
                    <div
                      className={`${
                        location.pathname === "/about-us"
                          ? "text-white font-semibold"
                          : "text-primary"
                      } font-medium text-sm`}
                    >
                      About
                    </div>
                  </NavLink>

                  <NavLink
                    to={"/contact-us"}
                    className={`py-2 px-4 border border-primary rounded-lg flex items-center ${
                      location.pathname === "/contact-us" ? "bg-primary" : ""
                    }`}
                  >
                    <i
                      className={`bi bi-telephone text-lg ${
                        location.pathname === "/contact-us"
                          ? "text-white font-semibold"
                          : "text-primary"
                      } me-2`}
                    ></i>
                    <div
                      className={`${
                        location.pathname === "/contact-us"
                          ? "text-white font-semibold"
                          : "text-primary"
                      } font-medium text-sm`}
                    >
                      Contact
                    </div>
                  </NavLink>

                  {userData ? (
                    <div
                      className="py-2 px-4 border border-secondary rounded-lg flex items-center"
                      onClick={() => {
                        setLoading(true);
                        Cookies.remove("authDetails");
                        setTimeout(() => {
                          setLoading(false);
                          navigation("/");
                        }, 1000);
                      }}
                    >
                      <i className="bi bi-box-arrow-left text-lg text-secondary me-2"></i>
                      <div className={"text-secondary font-medium text-sm"}>
                        Logout
                      </div>
                    </div>
                  ) : (
                    <NavLink
                      to={"/login"}
                      className="py-2 px-4 border border-secondary rounded-lg flex items-center"
                    >
                      <i className="bi bi-box-arrow-right text-lg text-secondary me-2"></i>
                      <div className={"text-secondary font-medium text-sm"}>
                        Login
                      </div>
                    </NavLink>
                  )}

                  {userData === false && (
                    <NavLink
                      to={"/signUp"}
                      className="py-2 px-4 border border-white custom-button-bg rounded-lg flex items-center"
                    >
                      <i className="bi bi-person-plus text-lg text-white me-2"></i>
                      <div className={"text-white font-medium text-sm"}>
                        Sign Up
                      </div>
                    </NavLink>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
