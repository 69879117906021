import React from 'react'
interface checkboxProps {
    setValue ?: ()=> void ,
    checked ?: boolean,
}
const CheckBox : React.FC<checkboxProps> = (props) => {
  return (
    <div className="checkbox-wrapper-13">
  <input id="c1-13" type="checkbox" onChange={props.setValue} checked={props.checked}/>
</div>



  )
}

export default CheckBox