import React from 'react'
import AccordionItem from './AccordionItem';
import { AvatarGroup } from '../theme/images';
import { NavLink } from 'react-router-dom';

const accordionData= [
    {
      title: 'Is there a free trial available?',
      content: 'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
      borderBottom : 'border-b border-input',
    },
    {
      title: 'Can I change my plan later?',
      content: 'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
      borderBottom : 'border-b border-input',
    },
    {
      title: 'What is your cancellation policy?',
      content: 'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
      borderBottom : 'border-b border-input',
    },
    {
      title: 'Can other info be added to an invoice?',
      content: 'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
      borderBottom : 'border-b border-input',
    },
    {
      title: 'How does billing work?',
      content: 'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
      borderBottom : 'border-b border-input',
    },

    {
        title: 'How do I change my account email?',
        content: 'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
        borderBottom : '',
      },
];

interface faqProps {
  isAuth ?: boolean
}

const FAQ : React.FC<faqProps> = (props) => {
  return (
    <>
    <section className='lg:py-24 md:py-20 pt-16 pb-5 xl:px-32 lg:px-28 md:px-24 sm:px-20 px-8 bg-white'>
        <div className="grid place-items-center">
        <h1 className='lg:text-4xl text-inputInner font-semibold md:text-3xl text-2xl mb-4'>Frequently asked questions</h1>
        <p className='text-center text-secondary md:text-base sm:text-sm text-xs font-normal'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

        <div className="accordion sm:mt-9 mt-7 w-full 2xl:px-96 xl:px-72 md:px-20 mb-10">
        <div className="grid gap-4">
        {accordionData.map((accordion, index) => (
          <AccordionItem
            key={index}
            title={accordion.title}
            content={accordion.content}
            border = {accordion.borderBottom}
          />
        ))}
      </div>
        </div>

        <div className='get-in-touch w-full bg-[#F9FAFB] sm:py-6 py-4 rounded-lg'>
            <div className='grid place-items-center'>
                <img src={AvatarGroup} alt="" className='sm:w-auto sm:h-auto h-12 w-24 mb-6' />
                <div className='md:text-lg text-center sm:text-base text-sm font-semibold text-inputInner mb-2'>Still have questions?</div>
                <p className='text-secondary text-center md:text-base sm:text-sm text-xs'>Can't find the answer you're looking for? Please chat to our friendly team.</p>
                {props.isAuth !== true && <NavLink to={'/contact-us'} type="button" className="text-white custom-button-bg mt-6 font-semibold rounded-lg text-sm px-4 py-2 text-center lg:w-44 sm:w-40 w-36 ">Get in touch</NavLink>}
            </div>
        </div>
        </div>
    </section>
    </>
  )
}

export default FAQ