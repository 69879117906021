import React,{useState,useEffect} from 'react'
import Navbar from '../components/Navbar'
import AboutCompany from '../components/AboutCompany'
import JobSeekers from '../components/JobSeekers'
import Transitions from '../components/Transitions'
import AccountState from '../States/AccountState'
import { CookiesData } from '../Functions/Cookies'
import Lottie from 'lottie-react'
import Loader from '../lottie/loading animation.json'
const About = () => {
  const [authDetails,setAuthDetails] = useState(false) 
  const [loader,setLoader] = useState(false)
  useEffect(()=>{
    const boolean = CookiesData()
    setAuthDetails(boolean)
  },[])
  return (
    <>
    {loader && <Lottie  animationData={Loader} loop={true} style={{position :'absolute',backgroundColor:'rgba(17, 24, 29, 0.7)',height:'100vh',width:'100vw',zIndex:'2000'}}/>}
    <AccountState>
    <Navbar setAuth={setAuthDetails} setLoader={setLoader}/>
    </AccountState>
    <Transitions>
    <AboutCompany/>
    <JobSeekers order1='lg:order-last order-first' order2='lg:order-first order-last' isAuth = {authDetails}/>
    </Transitions>
    </>
  )
}

export default About