import React,{useState} from 'react'
import Navbar from '../components/Navbar'
import Transitions from '../components/Transitions'
import { Organization1 ,Buildings,UserGroup} from '../theme/images'
import { AnimatePresence, motion } from 'framer-motion'
import AudioRecorder from '../components/AudioRecorder'
import { useNavigate } from 'react-router-dom'
import AccountState from '../States/AccountState'
const CompanyProfile = () => {
   const [visible,setVisible] = useState(false)
   const navigation = useNavigate()
  return (
    <>
    <AccountState>
    <Navbar/>
    </AccountState>
    <Transitions>
        <div className="company-details  xl:px-32 lg:px-28 md:px-24 sm:px-20 px-8 py-28 bg-tertiary">
           <div className="grid grid-cols-1 gap-4">

            <div className="flex flex-col bg-white p-5 shadow-md rounded-xl">
                <div className="grid grid-cols-8 gap-4 mb-3">
                    <div className='flex flex-col col-span-6'>
                    <div className='text-inputInner text-xl font-semibold'>React Developer</div>
                    <div className='text-base text-secondary font-normal'>Capgemini</div>
                    <div className='flex'>
                        <div className='flex items-center pe-2 border-r border-gray-300'><i className='bi bi-star-fill text-[#FFC000] text-sm'></i> <span className='text-sm text-secondary font-semibold ps-1'>4.0</span></div>
                        <div className='flex items-center ps-2'><span className='text-sm text-secondary font-light ps-1'>28075 Reviews</span></div>
                        </div>
                    </div>
                    <div className="col-span-2 flex justify-end">
                        <img src={Organization1} className='h-20 w-20' />
                    </div>
                </div>

                <div className="flex flex-col w-full space-y-1 pb-3 border-b border-gray-300">
                    <div className="flex">
                        <div className="flex items-center text-secondary font-semibold text-sm space-x-2 border-r border-gray-300 pe-2">
                            <i className='bi bi-briefcase text-base'></i>
                            <div>6-11 years</div>
                        </div>
                        <div className="flex items-center text-secondary font-semibold text-sm space-x-1 ps-2">
                            <i className='bi bi-currency-dollar text-base'></i>
                            <div>Not Disclosed</div>
                        </div>
                    </div>

                    <div className="flex items-center text-secondary font-semibold text-sm space-x-1">
                            <i className='bi bi-pin text-base'></i>
                            <div>Hybrid</div>
                        </div>

                        <div className="flex items-center text-secondary font-semibold text-sm space-x-1">
                            <i className='bi bi-geo-alt text-base'></i>
                            <div>Pune, Maharashtra, Bengaluru, Mumbai (All Areas)</div>
                        </div>
                </div>

                <div className="flex pt-4">
                <div className="flex items-center text-secondary text-sm space-x-1 border-r border-gray-300 pe-2">
                            <div className='font-normal'>Posted:</div>
                            <div className='font-semibold'>4 days ago</div>
                        </div>
                        <div className="flex items-center text-secondary text-sm space-x-1 border-r border-gray-300 px-2">
                            <div className='font-normal'>Openings:</div>
                            <div className='font-semibold'>3</div>
                        </div>

                        <div className="flex items-center text-secondary text-sm space-x-1 px-2">
                            <div className='font-normal'>Applicants:</div>
                            <div className='font-semibold'>1235</div>
                        </div>
                </div>
            </div>

            <div className="flex flex-col bg-white p-5 shadow-md rounded-xl">
                <div className="flex flex-col mb-5">
                <div className='text-inputInner text-xl font-semibold mb-2'>Job Description</div>
                <ul className='list-disc text-sm text-secondary font-normal px-5'>
                    <li className='font-semibold text-inputText'>5+ years of experience in Software Development Required.</li>
                    <li className='font-semibold text-inputText'>3+ years of experience with React JS Required</li>
                    <li className='font-semibold text-inputText'>2+ years of experience with Redux Required</li>
                    <li className='font-semibold text-inputText'>2+ years of experience with ES6 Required</li>
                    <li className='font-semibold text-inputText'>4+ years of experience with JavaScript, HTML5, CSS3 Required</li>
                    <li>Strong knowledge of web architecture/design and web best practices Required</li>
                    <li>Evaluating and understanding Business and Functional requirements</li>
                    <li>Proficient with tools such as Git, Bitbucket, JIRA, Confluence</li>
                    <li>Strong communication and problem-solving skills are necessary</li>
                    <li>Experience working in an Agile/Scrum environment.</li>
                    </ul>                
                </div>

                <div className="text-center">
                <button 
                className='w-60 py-2 bg-primary text-white text-sm font-semibold rounded-xl'
                onClick={()=>{
                    navigation('/ai-interview')
                }}
                >Give an Interview</button>
                </div>
            </div>
           </div>
        </div>
    </Transitions>
    </>
  )
}

export default CompanyProfile