import React,{Fragment,useEffect, useRef, useState} from 'react'
import { Menu,Transition } from '@headlessui/react'

interface filterBarProps {

}
const FilterBar : React.FC<filterBarProps> = (props) => {
  return (
    <>
    <div className='pt-20 pb-10'>
        <div className="grid xl:grid-cols-5 sm:grid-cols-3 grid-cols-3 gap-6">
            <div className="xl:col-span-2 sm:col-span-3 col-span-2 relative">
            <span className="absolute inset-y-0 left-3 flex items-center pl-2">
        <button type="submit" className="p-1 focus:outline-none text-sm">
        <i className="bi bi-search"></i>
        </button>
      </span>
      <input type="search" name="search" className="py-3 sm:text-sm text-xs w-full text-inputInner bg-white rounded-3xl pl-12 focus:outline-none border border-transparent focus:border-focusBorder focus:ring-4 focus:ring-focusRing  placeholder:text-md placeholder:sm:text-xs placeholder:text-[#98A2B3]" placeholder="Search jobs"/>
            </div>
            <div className='w-full sm:block hidden relative'>
              <div className='py-3 w-full bg-white md:px-6 px-4 space-x-2 text-sm border-none flex items-center text-inputInner rounded-3xl cursor-pointer'>
              <i className="bi bi-briefcase md:text-sm text-xs text-inputInner"></i>
              <select name="" id="" className='w-full p-0 border-none text-sm focus:outline-none focus:border-none focus:shadow-none focus:ring-0 cursor-pointer'>
                <option value="none" selected>Categories</option>
                <option value="it_1">IT Sector 1</option>
                <option value="it_2">IT Sector 2</option>
                <option value="it_3">IT Sector 3</option>
                <option value="it_4">IT Sector 4</option>
              </select>
              </div>
            </div>

            <div className='w-full sm:block hidden relative'>
              <div className='py-3 w-full bg-white md:px-6 px-4 space-x-2 text-sm border-none flex items-center text-inputInner rounded-3xl cursor-pointer'>
              <i className="bi bi-geo-alt md:text-sm text-xs text-inputInner"></i>
              <select name="" id="" className='w-full p-0 border-none text-sm focus:outline-none focus:border-none focus:shadow-none focus:ring-0 cursor-pointer'>
                <option value="none" selected>Location</option>
                <option value="l_1">Location 1</option>
                <option value="l_2">Location 2</option>
                <option value="l_3">Location 3</option>
                <option value="l_4">Location 4</option>
              </select>
              </div>
            </div>

            <div className='w-full sm:block hidden relative'>
              <div className='py-3 w-full bg-white md:px-6 px-4 space-x-2 text-sm border-none flex items-center text-inputInner rounded-3xl cursor-pointer'>
              <i className="bi bi-currency-dollar md:text-sm text-xs text-inputInner"></i>
              <select name="" id="" className='w-full p-0 border-none text-sm focus:outline-none focus:border-none focus:shadow-none focus:ring-0 cursor-pointer'>
                <option value="none" selected>Rate</option>
                <option value="$60/hr">60/hr</option>
                <option value="$70/hr">70/hr</option>
                <option value="$80/hr">80/hr</option>
                <option value="$100/hr">100/hr</option>
              </select>
              </div>
            </div>

            <div className="sm:hidden flex items-center justify-end">
            <Menu>
                <Menu.Button>
                <i className="bi bi-funnel py-3 px-3 rounded-full bg-white text-sm text-inputInner"></i>
                </Menu.Button>
                <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className={'absolute border z-50 border-primary right-10 top-32 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'}>
                <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary text-white' : 'text-inputInner'
                    } group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                  >
                    <i className="bi bi-briefcase md:text-sm text-xs text-inputInner"></i>
                    <span className='ms-2'>Categories</span>
                  </button>
                )}
                </Menu.Item>

                <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary text-white' : 'text-inputInner'
                    } group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                  >
                    <i className="bi bi-geo-alt md:text-sm text-xs text-inputInner"></i>
                    <span className='ms-2'>Location</span>
                  </button>
                )}
                </Menu.Item>

                <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary text-white' : 'text-inputInner'
                    } group flex w-full items-center rounded-md px-2 py-2 text-xs`}
                  >
                    <i className="bi bi-currency-dollar md:text-sm text-xs text-inputInner"></i>
                    <span className='ms-2'>Rate</span>
                  </button>
                )}
                </Menu.Item>
            </Menu.Items>
        </Transition>
            </Menu>
            </div>
        </div>
    </div>
    </>
  )
}

export default FilterBar