import React from 'react'
import { Organization1 } from '../theme/images'
import { NavLink } from 'react-router-dom'

const Organizations = () => {
  return (
    <div className='space-y-3'>
        {/* <NavLink to={'/company-profile'} className="grid grid-rows-3 gap-1 grid-flow-col grid-cols-12 px-5 py-4 bg-white rounded-2xl">
            <div className="md:row-span-3 row-span-1  col-span-1 flex items-center">
            <img src={Organization1} alt="" className='xl:h-20 xl:w-20 lg:h-16 lg:w-16 md:h-12 md:w-12 sm:h-8 sm:w-8 h-8 w-8' />
            </div>
            <div className="row-span-1 col-span-11 md:order-none order-1">
                <div className="grid grid-cols-12">
                    <div className="col-span-9 place-items-start">
                    <div className='bg-[#ECFDF3] md:px-3 py-1 px-2  rounded-3xl text-center text-[#027A48] font-normal text-xs w-max'>Freelance</div>
                    </div>
                    <div className="col-span-3 ms-auto">
                    <i className="bi bi-bookmark text-base text-secondary me-3"></i>
                    <i className="bi bi-share text-base text-secondary"></i>
                    </div>
                </div>
            </div>
            <div className="row-span-1 col-span-11">
                <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-9 place-items-start">
                    <div className='w-full 2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs text-inputInner font-semibold'>Product Designer, B2B Dashboard/ Platform</div>
                    </div>
                    <div className="col-span-3 ms-auto">
                    <div className='w-full 2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs text-inputInner font-semibold'>$60 - 80/hr</div>
                    </div>
                </div>
            </div>
            <div className="row-span-1 col-span-11">
                <div className="grid grid-cols-12 gap-4">
                    <div className="sm:col-span-9 col-span-8 place-items-start">
                        <div className="grid md:grid-cols-12 sm:grid-cols-8 grid-cols-8 sm:gap-4 gap-2">
                    <div className="col-span-4 flex items-center">
                        <i className="bi bi-geo-alt md:text-base text-sm text-[#98A2B3]"></i>
                        <span className='ms-1 md:text-sm text-xs text-[#98A2B3]'>Unites States Only</span>
                    </div>
                    <div className="col-span-4 flex items-center">
                    <i className="bi bi-briefcase md:text-base text-base text-[#98A2B3]"></i>
                    <span className='ms-1 md:text-sm text-xs text-[#98A2B3]'>Design</span>
                    </div>
                    <div className="col-span-4 flex items-center">
                    <i className="bi bi-calendar md:text-base text-sm text-[#98A2B3]"></i>
                    <span className='ms-1 md:text-sm text-xs text-[#98A2B3]'>40hrs/week</span>
                    </div>
                    </div>
                    </div>
                    <div className="col-span-3 ms-auto">
                    <div className="ms-auto md:text-base text-sm text-[#98A2B3] text-end">24 Days ago</div>
                    </div>
                </div>
            </div>
        </NavLink> */}

    <NavLink to={'/company-profile'} className='bg-white p-4 w-full rounded-lg shadow-md sm:space-x-6 flex sm:flex-row flex-col relative'>
        <div className='sm:w-auto sm:h-auto flex items-center sm:justify-start justify-center'>
            <img src={Organization1} alt="" className='sm:w-auto sm:h-auto h-16 w-16' />
        </div>
        <div className='flex w-full'>
            <div className="flex flex-col space-y-2">
            <div className='bg-[#ECFDF3] md:px-3 py-1 px-2  rounded-3xl text-center text-[#027A48] font-normal text-xs w-max sm:relative absolute sm:top-0 top-[6%]'>Freelance</div>
            <div className='w-full 2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs text-inputInner font-semibold'>Product Designer, B2B Dashboard/ Platform</div>
            <div className=" sm:flex block sm:space-x-4 flex-wrap">
                <div className='sm:flex block items-center flex-grow-0'>
                <i className="bi bi-geo-alt md:text-base text-sm text-[#98A2B3]"></i>
                <span className='ms-1 md:text-sm text-xs text-[#98A2B3]'>Unites States Only</span>
                </div>
                <div className="flex items-center">
                <i className="bi bi-briefcase md:text-base text-base text-[#98A2B3]"></i>
                    <span className='ms-1 md:text-sm text-xs text-[#98A2B3]'>Design</span>
                </div>
                <div className="flex items-center">
                <i className="bi bi-calendar md:text-base text-sm text-[#98A2B3]"></i>
                    <span className='ms-1 md:text-sm text-xs text-[#98A2B3]'>40hrs/week</span>
                </div>
            </div>
            </div>

            <div className="flex flex-col ms-auto space-y-2 ps-4">
                <div className="flex justify-end sm:relative absolute sm:top-0 top-[4%] sm:right-0 right-[6%]">
                <i className="bi bi-bookmark text-base text-secondary me-3"></i>
                <i className="bi bi-share text-base text-secondary"></i>
                </div>
                <div className='w-full 2xl:text-2xl xl:text-xl lg:text-lg md:text-base sm:text-sm text-xs text-inputInner font-semibold'>$60 - 80/hr</div>
                <div className="sm:ms-auto md:text-base sm:text-sm text-xs text-[#98A2B3] sm:relative absolute sm:top-0 top-[14%] sm:right-0 right=[2%]">24 Days ago</div>
            </div>
        </div>
    </NavLink>
       
    </div>
  )
}

export default Organizations