//@ts-nocheck
import React, { useState , useContext,useEffect} from "react";
import Label from "../components/Label";
import InputBox from "../components/InputBox";
import axios from "axios";
import { Regex } from "../theme/Regex";
import { SignUpHero, Logo, Google, Facebook, LinkedIn , CheckCirclePrimary,CheckCircleSecondary} from "../theme/images";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ToastNotification from '../components/ToastNotification.jsx'
import Cookies from "js-cookie";
import AccountContext from "../Context/AccountContext";
import { CognitoIdentityProvider, } from "aws-sdk/clients/cognitoidentity";
import Lottie from "lottie-react";
import Loader from '../lottie/loading animation.json'
 

const SignUp = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [usernameBorder,setUsernameBorder] = useState('border border-input')
  const [validUsername,setValidUsername] = useState(false)
  const [email, setEmail] = useState("");
  const [emailText,setEmailText] = useState('')
  const [emailBorder, setEmailBorder] = useState("border border-input");
  const [validEmail, setValidEmail] = useState(false);
  const [passwordBorder, setPasswordBorder] = useState( "border border-input");
  const [validPassword, setValidPassword] = useState(false);
  const [loading,setLoading] = useState(false)
  const [newIcon, setNewIcon] = useState(false);
  const [lowerValidated, setLowerValidated]=useState(false);
  const [upperValidated, setUpperValidated]=useState(false);
  const [numberValidated, setNumberValidated]=useState(false);
  const [specialValidated, setSpecialValidated]=useState(false);
  const [lengthValidated, setLengthValidated]=useState(false);
  const [isPasswordValidation,setIsPassswordValidation] = useState(false)
  const {signUp} = useContext(AccountContext)
  const [isAuth,setIsAuth] = useState(false)
  const navigation = useNavigate()

  const handleRegistration = async (event) => {
    setLoading(true)
    signUp(email,username,password)
    .then((data)=>{
      console.log('SuccessFully Registerd',data)
      setLoading(false)
      navigation('/login')
    })
    .catch(err=>{
      console.log('Error',err)
      setLoading(false)
      setEmail('')
      setUsername('')
      setPassword('')
    })
  };

  function signInCheck(mail: String, password: String, username: String) {
    if (
      mail.trim() === "" ||
      Regex.EmailRegex.test(email) === false ||
      password.trim() === "" ||
      username.trim() === ""
    ) {
      return "bg-[#EAECF0] text-secondary";
    } else {
      return "bg-[#4984E3] text-white";
    }
  }

  function handleFocusPassword() {
    setValidPassword(false);
    setPasswordBorder("border border-input");
    setIsPassswordValidation(true)
  }
  function handleFocusEmail() {
    setEmailBorder('border border-input')
    setValidEmail(false);
  }
  function handleFocusUser(){
    setUsernameBorder('border border-input')
    setValidUsername(false)
  }

  function handlePasswordBlur(){
    setIsPassswordValidation(false)
  }

  const handlePasswordChange = (value)=>{
    setPassword(value)

    if(Regex.lower.test(value)){
      setLowerValidated(true);
    }
    else{
      setLowerValidated(false);
    }
    if(Regex.upper.test(value)){
      setUpperValidated(true);
    }
    else{
      setUpperValidated(false);
    }
    if(Regex.number.test(value)){
      setNumberValidated(true);
    }
    else{
      setNumberValidated(false);
    }
    if(Regex.special.test(value)){
      setSpecialValidated(true);
    }
    else{
      setSpecialValidated(false);
    }
    if(Regex.PassLength.test(value)){
      setLengthValidated(true);
    }
    else{
      setLengthValidated(false);
    }
  }

  useEffect(()=>{
    let authData = Cookies.get('authDetails')
    if(authData){
      setIsAuth(true)
    }
    else{
      setIsAuth(false)
    }
  },[])

  if(isAuth === true){
    navigation('/')
    return(
      <>
      <p>Already Logged In</p>
      </>
    )
  }

  else{

  return (
    <section className="h-screen w-screen">
      {loading && <Lottie  animationData={Loader} loop={true} style={{position :'absolute',backgroundColor:'rgba(17, 24, 29, 0.7)',height:'100%',width:'100%',zIndex:'200'}}/>}
      <div className="grid lg:grid-cols-2 grid-cols-1 md:place-items-center h-full w-full">
        <div className="flex flex-col md:px-32 sm:px-16 px-12 my-auto md:my-0">
          <img src={Logo} alt="" className="flex self-start" />
          <div className="md:text-3xl text-lg font-semibold tracking-wide mt-7">
            Let's setup your profile
          </div>
          <div className="md:text-base sm:text-sm text-xs text-secondary mt-2 md:w-96 w-full">
            Ready to take next step?
          </div>
          <div className="login-input mt-7">
            <Label
              className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
              name="Full Name"
            />
            <InputBox
              className={`input bg-white ${usernameBorder} font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 placeholder:sm:text-sm placeholder:text-xs`}
              type="text"
              placeholder="Enter your full name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onFocus={handleFocusUser}
            />

            {validUsername === true && (
              <div className="mt-2 text-sm text-red-500">
               Please enter a username.
              </div>
            )}
          </div>
          <div className="login-input mt-4">
            <Label
              className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
              name="Email"
            />
            <InputBox
              className={`input bg-white ${emailBorder} font-medium md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 placeholder:sm:text-sm placeholder:text-xs`}
              type="text"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={handleFocusEmail}
            />

            {validEmail === true && (
              <div className="mt-2 text-sm text-red-500">
               {emailText}
              </div>
            )}
          </div>

          <div className="login-input mt-4">
            <Label
              className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
              name="Password"
            />
            <InputBox
              className={`input bg-white ${passwordBorder} mb-2 font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 pe-8 placeholder:sm:text-sm placeholder:text-xs`}
              iconClass={`${
                newIcon === true ? "bi bi-eye-slash" : "bi bi-eye"
              } font-bold text-md absolute right-4 bottom-2 px-1 cursor-pointer`}
              iconClick={() => {
                setNewIcon(!newIcon);
              }}
              type={newIcon === true ? "text" : "password"}
              placeholder="Enter your password"
              value={password}
              onChange={(e) =>{handlePasswordChange(e.target.value)}}
              onFocus={handleFocusPassword}
              onBlur={handlePasswordBlur}
            />

            <div className={`border border-input w-full py-2.5 px-2.5 rounded-md ${isPasswordValidation ? 'password-validation-show' : 'password-validation-hide'} md:text-sm sm:text-sm text-xs space-y-2`}>
              <div className="flex items-center">
                <img src={lowerValidated ? CheckCirclePrimary : CheckCircleSecondary} alt="" className="w-5 h-5" />
                <div className={`ms-1 sm:text-sm text-xs ${ lowerValidated ? 'text-inputInner' : 'text-secondary'}`}>At least one lowercase letter</div>
              </div>

              <div className="flex items-center">
                <img src={upperValidated ? CheckCirclePrimary : CheckCircleSecondary} alt="" className="w-5 h-5" />
                <div className={`ms-1 sm:text-sm text-xs ${ upperValidated ? 'text-inputInner' : 'text-secondary'}`}>At least one uppercase letter</div>
              </div>

              <div className="flex items-center">
                <img src={numberValidated ? CheckCirclePrimary : CheckCircleSecondary} alt="" className="w-5 h-5" />
                <div className={`ms-1 sm:text-sm text-xs ${numberValidated ? 'text-inputInner' : 'text-secondary'}`}>At least one number</div>
              </div>

              <div className="flex items-center">
                <img src={specialValidated ? CheckCirclePrimary : CheckCircleSecondary} alt="" className="w-5 h-5" />
                <div className={`ms-1 sm:text-sm text-xs ${specialValidated ? 'text-inputInner' : 'text-secondary'}`}>At least one special character</div>
              </div>

              <div className="flex items-center">
                <img src={lengthValidated ? CheckCirclePrimary : CheckCircleSecondary} alt="" className="w-5 h-5" />
                <div className={`ms-1 sm:text-sm text-xs ${ lengthValidated ? 'text-inputInner' : 'text-secondary'}`}>At least 8 characters</div>
              </div>
            </div>

            {validPassword === true && (
              <div className="mt-2 md:text-sm sm:text-sm text-xs text-red-500">
                Please enter a password.
              </div>
            )}
          </div>
          <ToastNotification/>
          <div
            className={`w-full mt-4 py-2 text-center text-sm md:text-md font-semibold cursor-pointer ${signInCheck(
              email,
              password,
              username
            )} rounded-md`}
            onClick={() => {
              let isValid = true;
              if (username.trim() === "") {
                setUsernameBorder('border border-red-400')
                setValidUsername(true)
                isValid = false;
              }
              if (email.trim() === "") {
                setEmailBorder("border border-red-400");
                setValidEmail(true);
                setEmailText('Please enter an email address.')
                isValid = false;
              }

              else if(Regex.EmailRegex.test(email) === false){
                setEmailBorder("border border-red-400");
                setValidEmail(true);
                setEmailText('Please enter a valid email address.')
                isValid = false;
              }

              if (password.trim() === "") {
                setPasswordBorder("border border-red-400");
                setValidPassword(true);
                isValid = false;
              } 
              else if(!numberValidated || !lowerValidated || !upperValidated || !specialValidated || !lengthValidated)
                {
                isValid = false
                toast.error('Check Password Validation')
              }
              
              
              if(isValid){
                handleRegistration()
              };
            }}
          >
            Create Account
          </div>
          <div className="grid grid-cols-3 mt-3 gap-4">
            <div className="py-1.5 rounded-lg border border-input flex w-full justify-center items-center cursor-pointer">
              <img src={Google} alt="Google" />
            </div>

            <div className="py-1.5  rounded-lg border border-input flex w-full justify-center items-center cursor-pointer">
              <img src={Facebook} alt="Facebook" />
            </div>

            <div className="py-1.5  rounded-lg border border-input flex w-full justify-center items-center cursor-pointer">
              <img src={LinkedIn} alt="Facebook" />
            </div>
          </div>

          <div className="mt-6 text-sm w-full text-secondary text-center">
            Already have an account?{" "}
            <NavLink
              to={"/login"}
              className="text-[#4984E3] font-semibold cursor-pointer"
            >
              Sign In
            </NavLink>
          </div>
        </div>
        <div className="w-full h-full bg-focusRing items-center justify-center hidden lg:flex">
          <img src={SignUpHero} alt="" />
        </div>
      </div>
    </section>
  );
  }
};

export default SignUp;
