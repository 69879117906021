import React,{useState,useEffect,useRef} from 'react'
import Navbar from '../components/Navbar'
import FilterBar from '../components/FilterBar'
import Organizations from '../components/Organizations'
import Transitions from '../components/Transitions'
import AccountState from '../States/AccountState'

const FindJob = () => {
  const [categoriesClick,setCategoriesClick] = useState(false)
  const [locationClick,setLocationClick] = useState(false)
  const [rateClick,setRateClick] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setCategoriesClick(false);
      setLocationClick(false);
      setRateClick(false)
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
    <AccountState>
    <Navbar/>
    </AccountState>
    <Transitions>
    <section className='w-full h-screen bg-tertiary xl:px-32 lg:px-28 md:px-24 sm:px-20 px-8' >
      <div ref={dropdownRef}>
        <FilterBar/>
        <Organizations/>
        </div>
    </section>
    </Transitions>
    </>
  )
}

export default FindJob