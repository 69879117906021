import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import Companies from '../components/Companies'
import AboutUs from '../components/AboutUs'
import JobSeekers from '../components/JobSeekers'
import FindJob from '../components/FindJob'
import FAQ from '../components/FAQ'
import Testimonials from '../components/Testimonials'
import Footer from '../components/Footer'
import Dummy from '../components/Dummy'
import Transitions from '../components/Transitions'
import AccountState from '../States/AccountState'
import { CookiesData } from '../Functions/Cookies'
import Lottie from 'lottie-react'
import Loader from '../lottie/loading animation.json'

const Dashboard = () => {
  const [authDetails,setAuthDetails] = useState(false) 
  const [loader,setLoader] = useState(false)
  useEffect(()=>{
    const boolean = CookiesData()
    setAuthDetails(boolean)
  },[])
  return (
    <>
{loader && <Lottie  animationData={Loader} loop={true} style={{position :'absolute',backgroundColor:'rgba(17, 24, 29, 0.7)',height:'100vh',width:'100vw',zIndex:'2000'}}/>}
    <AccountState>
    <Navbar setAuth={setAuthDetails} setLoader = {setLoader}/>
    </AccountState>
    <Transitions>
    <HeroSection/>
    <Companies/>
    <AboutUs/>
    <JobSeekers isAuth = {authDetails}/>
    <FindJob isAuth = {authDetails}/>
    <FAQ isAuth = {authDetails}/>
    <Testimonials/>
    <Footer/>
    </Transitions>
    </>
  )
}

export default Dashboard