import React,{useEffect,useState} from "react";
import { StartHero } from "../theme/images";
import { NavLink } from "react-router-dom";
import AccountState from '../States/AccountState'
import { CookiesData } from '../Functions/Cookies'
import Navbar from "../components/Navbar";
import Lottie from 'lottie-react'
import Loader from '../lottie/loading animation.json'
import Footer from "../components/Footer";
const StartInterview = () => {
  const [authDetails,setAuthDetails] = useState(false) 
  const [loader,setLoader] = useState(false)
  useEffect(()=>{
    const boolean = CookiesData()
    setAuthDetails(boolean)
  },[])
  return (
    <>
    <div className="h-screen w-screen ">
      {loader && <Lottie  animationData={Loader} loop={true} style={{position :'absolute',backgroundColor:'rgba(17, 24, 29, 0.7)',height:'100vh',width:'100vw',zIndex:'2000'}}/>}
    <AccountState>
    <Navbar setAuth={setAuthDetails} setLoader={setLoader}/>
    </AccountState>
      <div className="grid lg:grid-cols-2 grid-cols-1 place-items-center h-full w-full">
        <div className="flex flex-col container justify-center h-full w-full 2xl:px-28 xl:px-24 lg:px-16 md:px-14 sm:px-10 px-6">
          <div className="xl:text-4xl lg:text-2xl md:text-xl sm:text-lg text-base font-bold">
            <span className="text-primary">Get Ready</span> For Your INTERVIEW
          </div>
          <div className="md:text-base sm:text-sm text-xs text-secondary mt-2 w-full">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text.
          </div>
          <div className="flex flex-col my-8 space-y-2">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="Checkbox1"
                className="mr-2 ring-0 rounded focus:outline-none focus:ring-0"
              />
              <label
                htmlFor="Checkbox1"
                className="select-none text-primary cursor-pointer md:text-base sm:text-sm text-xs"
              >
                Lorem Ipsum is simply dummy text of the printing
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="Checkbox2"
                className="mr-2 ring-0 rounded focus:outline-none focus:ring-0"
              />
              <label
                htmlFor="Checkbox2"
                className="select-none text-primary cursor-pointer md:text-base sm:text-sm text-xs"
              >
                Lorem Ipsum is simply dummy text of the printing
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="Checkbox3"
                className="mr-2 ring-0 rounded focus:outline-none focus:ring-0"
              />
              <label
                htmlFor="Checkbox3"
                className="select-none text-primary cursor-pointer md:text-base sm:text-sm text-xs"
              >
                Lorem Ipsum is simply dummy text of the printing
              </label>
            </div>
          </div>

          <NavLink to={'/ai-interview'}>
            <button className="text-white bg-primary font-semibold lg:w-1/4 w-1/2 lg:text-base rounded py-3 text-sm">
              Start Interview
            </button>
          </NavLink>
        </div>
        <div className="w-full h-full items-center justify-center lg:flex hidden">
          <img src={StartHero} alt="" />
        </div>
      </div>
      <Footer/>
    </div>

    </>
  );
};

export default StartInterview;
