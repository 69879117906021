import React from 'react'
import { CheckIcon,DashboardQuality } from '../theme/images'
import { NavLink } from 'react-router-dom'

interface jobSeekersProps {
  order1 ?: string
  order2 ?: string
  isAuth?: boolean
}

const JobSeekers : React.FC<jobSeekersProps> = (props) => {
  return (
    <>
    <section className='lg:py-24 md:py-20 py-16 xl:px-32 lg:px-28 md:px-24 sm:px-20 px-8'>
      <div className="grid lg:grid-cols-2 place-content-center w-full lg:gap-4">
        <div className={`xl:px-5 lg:px-5 ${props.order1}`}>
      <h1 className='xl:text-5xl md:text-4xl sm:text-3xl text-2xl font-semibold text-inputInner'>Why job seekers love us</h1>
      <ul className='md:mt-10 mt-5 px-3 space-y-5'>
        <li className='flex'><img src={CheckIcon} alt="" className='h-5 w-5' /> <span className='ms-2 mt-[-0.2rem] text-secondary md:text-base text-sm'>Unique jobs at startups and tech companies you can't find anywhere else.</span></li>
        <li className='flex'><img src={CheckIcon} alt="" className='h-5 w-5' /> <span className='ms-2 mt-[-0.2rem] text-secondary md:text-base text-sm'>Say goodbye to cover letters - your profile is all you need. One click to apply and you're done.</span></li>
        <li className='flex'><img src={CheckIcon} alt="" className='h-5 w-5' /> <span className='ms-2 mt-[-0.2rem] text-secondary md:text-base text-sm'>Everything you need to know to job search - including seeing salary and stock options upfront when looking.</span></li>
        <li className='flex'><img src={CheckIcon} alt="" className='h-5 w-5' /> <span className='ms-2 mt-[-0.2rem] text-secondary md:text-base text-sm'>Connect directly with founders at top startups - no third party recruiters allowed.</span></li>
      </ul>

      {props.isAuth !== true &&  <NavLink to={'/signUp'} type="button" className="text-white custom-button-bg ms-10 mt-6 font-semibold rounded-lg text-sm px-4 py-2 text-center lg:w-44 sm:w-40 w-36">SignUp Now</NavLink>}
      </div>

      <div className={`items-center justify-center hidden lg:flex ${props.order2}`}>
              <img src={DashboardQuality} alt="" />
            </div>
      </div>
    </section>
    
    </>
  )
}

export default JobSeekers