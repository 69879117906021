import React from 'react'
import { Charge,Service,Mail } from '../theme/images'

const AboutUs = () => {
  return (
    <>
    <section className='w-full bg-[#FCFCFD] lg:py-24 md:py-20 sm:pt-16 py-14'>
        <div className='lg:mx-20 md:mx-16 sm:mx-14 mx-10'>
        <h1 className='text-inputInner font-semibold lg:text-4xl md:text-3xl sm:text-2xl text-xl text-center'>At Jobboost, we're changing the</h1>
        <h1 className='text-inputInner font-semibold lg:text-4xl md:text-3xl sm:text-2xl text-xl text-center'>way work works-for good</h1>
        <div className='md:mt-5 mt-3'>
            <p className='text-center text-secondary md:text-base sm:text-sm text-xs font-normal'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <p className='text-center text-secondary md:text-base sm:text-sm text-xs font-normal'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
        </div>
        </div>

        <div className="features md:mt-16 sm:mt-12 mt-10 lg:mx-20 md:mx-16 sm:mx-14 mx-10">
            <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items-center lg:gap-4 md:gap-3 sm:gap-2 gap-1">
                <div className="features-card  w-full h-full flex flex-col justify-center items-center md:px-16 sm:px-12 px-0">
                    <img src={Mail} alt="" />
                    <div className='md:text-lg text-sm text-inputInner font-medium text-s mt-4 text-center'>Get Expertly Matched</div>
                    <p className='text-center text-secondary md:text-sm text-xs font-normal mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                    <div className='text-primary lg:text-base text-sm mt-5 font-semibold cursor-pointer'><span>Learn More</span> <i className='bi bi-arrow-right'></i></div>
                </div>

                <div className="features-card lg:mt-0 mt-3 w-full h-full flex flex-col justify-center items-center md:px-16 sm:px-12 px-0">
                    <img src={Charge} alt="" />
                    <div className='text-center md:text-lg text-sm text-inputInner font-medium text-s mt-4'>Future-proof your career</div>
                    <p className='text-center text-secondary md:text-sm text-xs font-normal mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                    <div className='text-primary lg:text-base text-sm mt-5 font-semibold cursor-pointer'><span>Learn More</span> <i className='bi bi-arrow-right'></i></div>
                </div>

                <div className="features-card lg:mt-0 mt-3 w-full h-full flex flex-col justify-center items-center lg:px-16 md:px-12 px-0">
                    <img src={Service} alt="" />
                    <div className='text-center md:text-lg text-sm text-inputInner font-medium text-s mt-4'>Get fully managed tech services</div>
                    <p className='text-center text-secondary md:text-sm text-xs font-normal mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                    <div className='text-primary lg:text-base text-sm mt-5 font-semibold cursor-pointer'><span>Learn More</span> <i className='bi bi-arrow-right'></i></div>
                </div>
            </div>
        </div>
    </section>
        </>
  )
}

export default AboutUs