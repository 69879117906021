import React,{useState} from 'react'
import Label from '../components/Label'
import InputBox from '../components/InputBox'
import { Regex } from '../theme/Regex'
import { ForgotHero} from '../theme/images'
import { NavLink, useNavigate } from 'react-router-dom'
const NewPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState('')
    const [newPasswordBorder,setNewPasswordBorder] = useState('border border-input')
    const [confirmPasswordBorder,setConfirmPasswordBorder] = useState('border border-input')
    const [validNewPassword,setValidNewPassword] = useState(false)
    const [validConfirmPassword,setValidConfirmPassword] = useState(false)
    const [confirmText,setConfirmText] = useState('Please confirm a new password.')
    const [confirmIcon,setConfirmIcon] = useState(false)
    const [newIcon,setNewIcon] = useState(false)
    const navigation = useNavigate()

    function signInCheck (newPass:String,confirmPass : string){
        if(newPass.trim() === '' ||  confirmPass.trim() === '' || (newPass !== confirmPass)){
            return 'bg-[#EAECF0] text-secondary'
        }
    
        else{
            return 'bg-[#4984E3] text-white'
        }
      }
    
      function handleFocusNew (){
        setValidNewPassword(false)
        setNewPasswordBorder('border border-input')
      }

      function handleFocusConfirm (){
        setConfirmPasswordBorder('border border-input')
        setValidConfirmPassword(false)
        setConfirmText('Please confirm a new password.')
      }
  return (
    <section className='h-screen w-screen'>
        <div className="grid lg:grid-cols-2 grid-cols-1 place-items-center h-full w-full">
            <div className='flex flex-col lg:mt-[-15rem] mt-[-10rem] md:px-10 sm:px-7 px-5'>
                <div className='md:text-3xl text-lg font-semibold tracking-wide'>Create new password</div>
                <div className="md:text-base sm:text-sm text-xs text-secondary mt-2 md:w-96 w-full">
                Welcome back! Please enter your details.
                </div>

                <div className="login-input mt-7">
                  <Label
                    className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
                    name="New Password"
                  />
                  <InputBox
                    className={`input bg-white ${newPasswordBorder} font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 pe-8`}
                    iconClass={`${newIcon === true ? 'bi bi-eye-slash' : 'bi bi-eye'} font-bold text-md absolute right-4 bottom-2 px-1 cursor-pointer`}
                    iconClick={()=>{
                        setNewIcon(!newIcon)
                    }}
                    type={newIcon === true ? 'text': 'password'}
                    placeholder="Enter your new password"
                    value={newPassword}
                    onChange={(e)=> setNewPassword(e.target.value)}
                    onFocus={handleFocusNew}
                  />
                  
                  {validNewPassword === true && <div className="mt-2 md:text-sm sm:text-sm text-xs text-red-500">Please enter a new password.</div>}
                </div>

                <div className="login-input mt-7">
                  <Label
                    className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
                    name="Create New Password"
                  />
                  <InputBox
                    className={`input bg-white ${confirmPasswordBorder} font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5 pe-8`}
                    iconClass={`${confirmIcon === true ? 'bi bi-eye-slash' : 'bi bi-eye'} font-bold text-md absolute right-4 bottom-2 px-1 cursor-pointer`}
                    iconClick={()=>{
                        setConfirmIcon(!confirmIcon)
                    }}
                    type={confirmIcon === true ? 'text': 'password'}
                    placeholder="Confirm your new password"
                    value={confirmPassword}
                    onChange={(e)=> setConfirmPassword(e.target.value)}
                    onFocus={handleFocusConfirm}
                  />
                  
                  {validConfirmPassword === true && <div className="mt-2 md:text-sm sm:text-sm text-xs text-red-500">{confirmText}</div>}
                </div>

                <div 
                className={`w-full mt-4 py-2 text-center text-sm md:text-md font-semibold cursor-pointer ${signInCheck(newPassword,confirmPassword)} rounded-md`}
                onClick={()=>{
                    if(newPassword.trim() === ''){
                        setNewPasswordBorder('border border-red-400')
                        setValidNewPassword(true)
                    }

                  if(confirmPassword.trim() === ''){
                        setConfirmPasswordBorder('border border-red-400')
                        setValidConfirmPassword(true)
                    }

                  else if(confirmPassword !== newPassword){
                    setConfirmPasswordBorder('border border-red-400')
                    setValidConfirmPassword(true)
                    setConfirmText('Password does not matches with the above password!')
                    }

                    else(
                        navigation('/login')
                    )
                }}
                >
                  Reset Password
                </div>
            </div>
            <div className="w-full h-full bg-focusRing items-center justify-center hidden lg:flex">
              <img src={ForgotHero} alt="" className='mt-[-10rem]'/>
            </div>
        </div>
    </section>
  )
}

export default NewPassword