import React from 'react'
import { LayersCompany,CatalogCompany,CircoolesCompany,QuotientCompany,SisyphusCompany } from '../theme/images'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay } from 'swiper/modules';

const Companies = () => {
  return (
    <>
    <div className="md:my-5 mt-0">
        <p className='text-center md:text-base sm:text-sm font-semibold text-[0.9rem] text-secondary lg:mx-20 md:mx-16 sm:mx-14 mx-10'>Access jobs with the world's leading enterprises</p>
        <div className="images-container lg:my-12 md:my-10 sm:my-8 my-7 lg:mx-20 md:mx-16 sm:mx-14 mx-10">
            <div className="md:grid md:grid-cols-5 hidden place-items-center gap-4">
                <div className="company-image flex justify-center">
                    <img src={LayersCompany} alt="" />
                </div>

                <div className="company-image flex justify-center">
                    <img src={CatalogCompany} alt="" />
                </div>

                <div className="company-image flex justify-center">
                    <img src={CircoolesCompany} alt="" />
                </div>

                <div className="company-image flex justify-center">
                    <img src={QuotientCompany} alt="" />
                </div>

                <div className="company-image flex justify-center">
                    <img src={SisyphusCompany} alt="" />
                </div>
            </div>

        <div className="inner md:hidden">
        <Swiper
        slidesPerView={2}
        spaceBetween={20}
        centeredSlides={false}
        loop={true}
        autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide className='layer'>
        <img src={LayersCompany} alt="" className='h-8'/>
        </SwiperSlide>

        <SwiperSlide className='layer'>
        <img src={SisyphusCompany} alt="" className='h-8'/>
        </SwiperSlide>

        <SwiperSlide className='layer'>
            <img src={CircoolesCompany} alt="" className='h-8'/>
            </SwiperSlide>

        <SwiperSlide className='layer'>
            <img src={CatalogCompany} alt="" className='h-8'/>
            </SwiperSlide>

        <SwiperSlide className='layer'>
            <img src={QuotientCompany} alt="" className='h-8'/>
            </SwiperSlide>
      </Swiper>
    </div>
        </div>
    </div>
    </>
  )
}

export default Companies