import React from 'react'
import { LogoWhite } from '../theme/images'
import moment from 'moment';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    const currentYear = moment().format('YYYY');
  return (
    <section className='lg:py-24 md:py-20 py-16 xl:px-32 lg:px-28 md:px-24 sm:px-20 px-8 bg-primary'>
        <div className="grid lg:grid-cols-4 grid-cols-2 gap-6 border-b border-white pb-7 mb-7">
            <div className="scoial lg:col-span-1 col-span-2">
                <img src={LogoWhite} alt="" className='mb-3' />
                <p className='text-left text-white md:text-base text-sm mb-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div className="flex space-x-5 items-center">
                <i className="bi bi-facebook md:text-xl text-lg  text-white cursor-pointer"></i>
                <i className="bi bi-twitter-x md:text-xl text-lg text-white cursor-pointer"></i>
                <i className="bi bi-instagram md:text-xl text-lg text-white cursor-pointer"></i>
                <i className="bi bi-linkedin md:text-xl text-lg text-white cursor-pointer"></i>
                <i className="bi bi-youtube md:text-2xl text-xl text-white cursor-pointer"></i>

                </div>
            </div>

            <div className="links xl:px-20 flex flex-col">
                <div className='text-white text-left text-xl md:text-lg sm:text-base font-bold mb-3'>Company</div>
                <NavLink to={'/about-us'} className='text-white text-left md:text-base text-sm font-semibold mb-2 cursor-pointer'>About Us</NavLink>
                <NavLink to={'/contact-us'} className='text-white text-left md:text-base text-sm font-semibold mb-2 cursor-pointer'>Contact Us</NavLink>
            </div>

            <div className="xl:col-span-2">
                <div className='text-white text-left text-xl md:text-lg sm:text-base font-bold mb-3'>Resources</div>
                <div className='text-white text-left md:text-base text-sm font-semibold mb-2 cursor-pointer'>Terms of Use</div>
                <div className='text-white text-left md:text-base text-sm font-semibold mb-2 cursor-pointer'>Privacy Policy</div>
            </div>
        </div>

        <p className='md:text-base text-sm text-white text-center font-normal'>&copy; {currentYear} All Rights Reserved.</p>
    </section>
  )
}

export default Footer