import Cookies from "js-cookie"

export function CookiesData (){
    const authData : any = Cookies.get('authDetails')
    if(authData !== undefined){
        return true
    }
    else{
        return false
    }
}
