const resultDummyData =  [
      {
        "question": "What is the capital of France?",
        "answer": "Paris",
        "feedback": "Correct!",
        "score": 8
      },
      {
        "question": "In which year did World War II end?",
        "answer": "1945",
        "feedback": "Well done!",
        "score": 9
      },
      {
        "question": "Who wrote 'Romeo and Juliet'?",
        "answer": "William Shakespeare",
        "feedback": "Excellent!",
        "score": 10
      },
      {
        "question": "What is the largest planet in our solar system?",
        "answer": "Jupiter",
        "feedback": "Great job!",
        "score": 7
      },
      {
        "question": "How many continents are there?",
        "answer": "7",
        "feedback": "Correct!",
        "score": 6
      },
      {
        "question": "What is the chemical symbol for gold?",
        "answer": "Au",
        "feedback": "Awesome!",
        "score": 5
      },
      {
        "question": "Who painted the Mona Lisa?",
        "answer": "Leonardo da Vinci",
        "feedback": "Fantastic!",
        "score": 3
      },
      {
        "question": "What is the square root of 64?",
        "answer": "8",
        "feedback": "Bravo!",
        "score": 9
      },
      {
        "question": "Which ocean is the largest?",
        "answer": "Pacific Ocean",
        "feedback": "Correct!",
        "score": 10
      },
      {
        "question": "What is the speed of light?",
        "answer": "299,792 kilometers per second",
        "feedback": "Outstanding!",
        "score": 9
      }
    ]
  
    export {resultDummyData}