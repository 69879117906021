import React, { useState } from 'react';

function DummyTextToSpeech() {
  const [text, setText] = useState('');
  const [language, setLanguage] = useState('en');

  const handleTextToSpeech = async () => {
    const apiUrl = 'http://localhost:5000/app';  // Update the URL based on your Flask app's host and port

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text, language }),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a link element and simulate a click to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'output.mp3';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('Failed to generate speech');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter text..."
      />
      <br />
      <label>
        Language:
        <input
          type="text"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          placeholder="Language code (e.g., en)"
        />
      </label>
      <br />
      <button onClick={handleTextToSpeech}>Generate Speech</button>
    </div>
  );
}

export default DummyTextToSpeech;
