import React, { useState } from 'react';
import { motion,AnimatePresence } from 'framer-motion';
interface AccordionItemProps {
  title: string;
  content: string;
  border : string;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, content,border }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`w-full  bg-white ${border}  pb-4`}>
      <button
        className="w-full py-1 mb-1 text-left sm:text-base text-sm font-semibold text-inputInner"
        onClick={toggleAccordion}
      >
        {title}
        <span className={`float-right  sm:h-6 sm:w-6 h-6 w-6 text-primary sm:text-base flex justify-center items-center  border-2 border-primary rounded-full text-center align-top`}>{isOpen? '-' :'+'}</span>
      </button>
      {isOpen && (
        <div className="pt-1 pb-2 text-secondary sm:text-sm text-xs xl:pe-8 md:pe-6 pe-3 transition duration-300 ease-in-out h-auto">
          {content}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
