import React,{useState} from 'react'
import Label from '../components/Label'
import InputBox from '../components/InputBox'
import { Regex } from '../theme/Regex'
import { ForgotHero } from '../theme/images'
import { NavLink, useNavigate } from 'react-router-dom'
const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailBorder,setEmailBorder] = useState('border border-input')
    const [validEmail,setValidEmail] = useState(false)
    const [emailPath,setEmailPath] = useState(false)
    const navigation = useNavigate()

    function signInCheck (mail:String){
        if(mail.trim() === '' ||  Regex.EmailRegex.test(email) === false){
            return 'bg-[#EAECF0] text-secondary'
        }
    
        else{
            return 'bg-[#4984E3] text-white'
        }
      }

    function handleBlur (){
        if(Regex.EmailRegex.test(email) === false && email.trim() !== ''){
                setEmailBorder('border border-red-400')
                setValidEmail(true)
        }
    
        else {
            setEmailBorder('border border-input')
        }
      }
    
      function handleFocus (){
        setValidEmail(false)
      }

    //   function emailPathFun (){
    //     if(email.trim() !== '' && Regex.EmailRegex.test(email)){
    //         return setEmailPath(true)
    //     }
    //     else {
    //         return setEmailPath(false)
    //     }
    //   }
  return (
    <section className='h-screen w-screen'>
        <div className="grid lg:grid-cols-2 grid-cols-1 place-items-center h-full w-full">
            <div className='flex flex-col lg:mt-[-18rem] mt-[-10rem] md:px-10 sm:px-7 px-5'>
                <NavLink to={'/login'} className='h-9 w-9 bg-white border border-input rounded-full flex justify-center items-center cursor-pointer mb-7'>
                <i className="bi bi-arrow-left md:text-md text-sm font-extrabold"></i> 
                </NavLink>

                <div className='md:text-3xl text-lg font-semibold tracking-wide'>Forgot your password?</div>
                <div className="md:text-base sm:text-sm text-xs text-secondary mt-2 md:w-96 w-full">
                Please enter the email address you used to sign up and we'll send you a password reset link.
                </div>

                <div className="login-input mt-7">
                  <Label
                    className="block mb-1 md:text-sm sm:text-sm text-xs font-medium text-inputText dark:text-white"
                    name="Email"
                  />
                  <InputBox
                    className={`input bg-white ${emailBorder} font-medium  md:text-sm sm:text-sm text-xs text-inputInner rounded-md focus:outline-none focus:border-focusBorder focus:ring-4 focus:ring-focusRing  block w-full py-2.5 px-2.5`}
                    type="text"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                  
                  {validEmail === true && <div className="mt-2 md:text-sm sm:text-sm text-xs text-red-500">Enter a valid Email address</div>}
                </div>

                <div 
                className={`w-full mt-4 py-2 text-center text-sm md:text-md font-semibold cursor-pointer ${signInCheck(email)} rounded-md`}
                onClick={()=>{
                    if(Regex.EmailRegex.test(email) !== false && email.trim() !== ''){
                        navigation('/getEmail')
                    }
                }}
                >
                  Reset Password
                </div>
            </div>
            <div className="w-full h-full bg-focusRing items-center justify-center hidden lg:flex">
              <img src={ForgotHero} alt="" className='mt-[-10rem]'/>
            </div>
        </div>
    </section>
  )
}

export default ForgotPassword