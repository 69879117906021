import React from 'react'
import { AboutHero } from '../theme/images'
const AboutCompany = () => {
  return (
    <>
    <section className='bg-white lg:py-36 md:py-20 pt-16 lg:mb-12 mb-0'>
        <div className="grid lg:grid-cols-2 grid-cols-1">
            <div className="about-description xl:ps-32 lg:ps-24 lg:px-0 md:px-24 sm:px-20 px-8  lg:py-10 py-5 w-full">  
                <div className='text-left xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-inputInner font-semibold'>Our purpose is to unlock the untapped potential within every individual in the world.</div>
                <div className='mt-3 lg:text-lg md:text-base text-sm font-normal text-secondary'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</div>
                <div className="mt-4 grid grid-cols-2 md:gap-5 gap-3">
                    <div className="flex flex-col justify-start">
                        <div className='text-inputInner font-semibold lg:text-3xl md:text-xl text-lg mb-2'>35,000+</div>
                        <div className='text-secondary md:text-base text-sm'>Companies that are now</div>
                    </div>

                    <div className="flex flex-col justify-start">
                        <div className='text-inputInner font-semibold lg:text-3xl md:text-xl text-lg mb-2'>100+</div>
                        <div className='text-secondary md:text-base text-sm'>Skills available</div>
                    </div>

                    <div className="flex flex-col justify-start">
                        <div className='text-inputInner font-semibold lg:text-3xl md:text-xl text-lg mb-2'>500,000+</div>
                        <div className='text-secondary md:text-base text-sm'>Job posted with our software</div>
                    </div>

                    <div className="flex flex-col justify-start">
                        <div className='text-inputInner font-semibold lg:text-3xl md:text-xl text-lg mb-2'>75%</div>
                        <div className='text-secondary md:text-base text-sm'>Of customers get developers</div>
                    </div>
                </div>
            </div>

            <div className='about-img relative lg:block hidden'>
                <img src={AboutHero} alt="" className='relative z-10 left-16 top-5' />
                <div className="img-overlay h-[150%] w-[70%] z-[1] absolute right-0 top-0 bg-[#E1EAFB]"></div>
            </div>
        </div>
    </section>
    </>
  )
}

export default AboutCompany