import React,{useState} from 'react'
import { Email,ForgotHero } from '../theme/images'
import { useNavigate } from 'react-router-dom'

const GetEmail = () => {
  const navigation = useNavigate()
  return (
    <section className='h-screen w-screen'>
      <div className="grid lg:grid-cols-2 place-items-center grid-cols-1 h-full w-full">
        <div className='flex flex-col lg:mt-[-15rem] mt-[-10rem] md:px-10 sm:px-7 px-5 place-items-center'>
          <img src={Email} alt="" />
          <div className='md:text-3xl text-lg font-semibold tracking-wide mt-7'>Check your email</div>
          <div className="md:text-base sm:text-sm text-xs text-secondary mt-2 md:w-96 text-center w-full">
          If there is a Jobboost account associated to this email address, we'll send you an OTP to the email address.
                </div>
                <div 
                className={`w-full mt-6 py-2 text-center sm:text-sm text-xs md:text-md font-semibold cursor-pointer bg-[#4984E3] text-white rounded-md`}
                onClick={()=>{
                  navigation('/otp')
                }}
                >
                  Enter OTP
                </div>
        </div>
        <div className="w-full h-full bg-focusRing items-center justify-center hidden lg:flex">
              <img src={ForgotHero} alt="" className='mt-[-10rem]'/>
            </div>
      </div>
    </section>
  )
}

export default GetEmail