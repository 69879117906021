import React from 'react'

interface inputProps {
    className : string,
    type : string,
    placeholder ?: string,
    setState ?: React.Dispatch<React.SetStateAction<string>>,
    value ?: string,
    onChange ?: (e: any)=> void,
    onBlur ?: ()=> void,
    onFocus ?: ()=> void,
    iconClass ?: string,
    iconClick ?: ()=> void,
}

const InputBox : React.FC<inputProps> = (props) => {
  return (
    <div className='relative'>
      <input type={props.type} className={props.className} placeholder={props.placeholder} value={props.value} onChange={props.onChange} onBlur={props.onBlur} onFocus={props.onFocus}/>
      <i className={props.iconClass} onClick={props.iconClick}></i>
      </div>
  )
}

export default InputBox